<template>
  <div>
    <div class="resources-container">
      <!-- <span class="bold-text">Resources</span>
        <span class="dashed-line"></span> -->
      <span class="normal-text">Start by checking out our</span>
      <span
        ><a
          href="javascript:void(0)"
          @click="goToGameResourcePage"
          class="playbook-link"
          >HOST PLAYBOOK</a
        ></span
      >
    </div>

    <v-data-table
      hide-default-footer
      fixed-header
      :items="items"
      :headers="selectedHeaders.header"
      :loading="loading"
      class="elevation-8"
      loading-text="Loading... Please wait"
      mobile-breakpoint="0"
      >{{ this.adminAccess }}
      <template v-slot:top>
        <div class="step-heading pt-5">
          <div class="header-container">
            <div class="things_card-heading">
              <h4 class="ticketTextHeading">
                Things to do
                <span
                  class="ticketTextLine"
                  style="width: 10px; height: 10px"
                ></span>
              </h4>
            </div>
            <div>
              <div class="stepper-wrapper row">
                <div
                  v-for="step in stepNames"
                  :key="step.id"
                  class="stepper-item"
                  :class="{
                    completed: progress_status.includes(step.id),
                    active: activeStep === step.id,
                  }"
                  @click="selectStep(step)"
                >
                  <div class="step-counter">
                    <!-- <img :src="activeStep === step.id ? step.activeIcon : step.inactiveIcon" /> -->
                    <img
                      :src="getIconForStep(step, getRowCount(step))"
                      :alt="step.name"
                    />
                  </div>
                  <div class="step-name">
                    <span>{{ step.name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <b><hr style="margin-bottom: 5px" /></b>
        </div>

        <!--  <div>
          <p class="textboxP">
            Sample text Sample text Sample text Sample text Sample text Sample
            text Sample text Sample text Sample text Sample text Sample text
            Sample text Sample text Sample text Sample text Sample text Sample
            text Sample text Sample text Sample text.
          </p>
        </div>-->

        <div class="d-flex justify-end pa-4 pt-2">
          <v-btn
            class="text-capitalize"
            height="44"
            dark
            color="#2C1963"
            style="border-radius: 10px"
            v-if="tabsSelectedValue === 'voucher'"
            >Bulk Voucher</v-btn
          >&nbsp;&nbsp;
          <v-btn
            class="text-capitalize"
            height="44"
            dark
            color="#2C1963"
            style="border-radius: 10px"
            @click="openModal"
          >
            <span>{{ selectedHeaders.text }}</span>
          </v-btn>
        </div>
      </template>

      <template v-slot:item="props" v-if="tabsSelectedValue === 'assembly'">
        <tr style="height: 84px">
          <td
            class="text-center"
            v-if="props.item.school == null"
            style="background-color: #ffa8b5"
          >
            <div class="tableText">{{ props.item.school_name }}</div>
            <div style="font-size: 12px; color: #757575">
              {{ props.item.school }}
            </div>
          </td>
          <td class="text-center" v-else>
            <div class="tableText">{{ props.item.school_name }}</div>
            <div style="font-size: 12px; color: #757575">
              {{ props.item.school }}
            </div>
          </td>
          <td
            class="text-center"
            v-if="props.item.school == null"
            style="background-color: #ffa8b5"
          >
            <div class="tableText">
              {{ props.item.assemble_date_2 }},{{ props.item.start_time_str }}
            </div>
          </td>
          <td class="text-center" v-else>
            <div class="tableText">
              {{ props.item.assemble_date_2 }},{{ props.item.start_time_str }}
            </div>
          </td>
          <td
            class="text-center py-2"
            v-if="props.item.school == null"
            style="background-color: #ffa8b5"
          >
            <div class="tableText">
              {{ props.item.name }}
            </div>
            <div style="font-size: 12px; color: #757575">
              <div>{{ props.item.phone }}</div>
              <div>{{ props.item.email }}</div>
            </div>
          </td>
          <td class="text-center py-2" v-else>
            <div class="tableText">
              {{ props.item.name }}
            </div>
            <div style="font-size: 12px; color: #757575">
              <div>{{ props.item.phone }}</div>
              <div>{{ props.item.email }}</div>
            </div>
          </td>
          <td
            class="text-center"
            v-if="props.item.school == null"
            style="background-color: #ffa8b5"
          >
            <v-btn
              color="#38227A"
              dark
              class="text-capitalize px-7 mr-5"
              @click="
                toggleAssemblyModal1({
                  show: true,
                  type: 'edit',
                  id: props.item.id,
                })
              "
              height="45px"
              style="border-radius: 10px; margin-left: 15px; font-size: 20px"
              v-if="
                $vuetify.breakpoint.smAndUp && props.item.user_type != 'game'
              "
            >
              <span>Update Booked Assembly</span>
            </v-btn>
            <v-btn
              color="#38227A"
              dark
              class="text-capitalize px-7 mr-5"
              @click="
                toggleAssemblyModal1({
                  show: true,
                  type: 'edit',
                  id: props.item.id,
                })
              "
              height="45px"
              style="border-radius: 10px; margin-left: 15px; font-size: 20px"
              v-if="
                $vuetify.breakpoint.smAndUp &&
                props.item.threshold_hours >= 24 &&
                props.item.user_type == 'game'
              "
            >
              <span>Update Booked Assembly</span>
            </v-btn>
            <v-btn
              color="#38227A"
              dark
              class="text-capitalize px-7 mr-5"
              @click="toggleMessageModal({ show: true })"
              height="45px"
              style="border-radius: 10px; margin-left: 15px; font-size: 20px"
              v-if="
                $vuetify.breakpoint.smAndUp &&
                props.item.threshold_hours < 24 &&
                props.item.user_type == 'game'
              "
            >
              <span>Update Booked Assembly</span>
            </v-btn>
            <v-btn
              color="#38227A"
              dark
              class="text-capitalize px-7 mr-5"
              @click="
                toggleAssemblyModal1({
                  show: true,
                  type: 'edit',
                  id: props.item.id,
                })
              "
              height="24px"
              style="
                border-radius: 10px;
                margin-left: 10px;
                width: 60%;
                font-size: 8px;
              "
              v-if="
                $vuetify.breakpoint.xsOnly && props.item.user_type != 'game'
              "
            >
              <span>Update Booked Assembly</span>
            </v-btn>
            <v-btn
              color="#38227A"
              dark
              class="text-capitalize px-7 mr-5"
              @click="
                toggleAssemblyModal1({
                  show: true,
                  type: 'edit',
                  id: props.item.id,
                })
              "
              height="24px"
              style="
                border-radius: 10px;
                margin-left: 10px;
                width: 60%;
                font-size: 8px;
              "
              v-if="
                $vuetify.breakpoint.xsOnly &&
                props.item.threshold_hours >= 24 &&
                props.item.user_type == 'game'
              "
            >
              <span>Update Booked Assembly</span>
            </v-btn>
            <v-btn
              color="#38227A"
              dark
              class="text-capitalize px-7 mr-5"
              @click="toggleMessageModal({ show: true })"
              height="24px"
              style="
                border-radius: 10px;
                margin-left: 10px;
                width: 60%;
                font-size: 8px;
              "
              v-if="
                $vuetify.breakpoint.xsOnly &&
                props.item.threshold_hours < 24 &&
                props.item.user_type == 'game'
              "
            >
              <span>Update Booked Assembly</span>
            </v-btn>
            <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="toggleDeleteModal({ show: true, id: props.item.id })"
              v-if="props.item.user_type != 'game'"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="22"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </v-chip>
            <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="
                toggleViewAssemblyModal({ show: true, id: props.item.id })
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="22"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <span>View</span>
              </v-tooltip>
            </v-chip>
          </td>
          <td class="text-center" v-else>
            <v-btn
              color="#38227A"
              dark
              class="text-capitalize px-7 mr-5"
              @click="
                toggleAssemblyModal1({
                  show: true,
                  type: 'edit',
                  id: props.item.id,
                })
              "
              height="45px"
              style="border-radius: 10px; margin-left: 15px; font-size: 20px"
              v-if="
                $vuetify.breakpoint.smAndUp && props.item.user_type != 'game'
              "
            >
              <span>Update Booked Assembly</span>
            </v-btn>
            <v-btn
              color="#38227A"
              dark
              class="text-capitalize px-7 mr-5"
              @click="
                toggleAssemblyModal1({
                  show: true,
                  type: 'edit',
                  id: props.item.id,
                })
              "
              height="45px"
              style="border-radius: 10px; margin-left: 15px; font-size: 20px"
              v-if="
                $vuetify.breakpoint.smAndUp &&
                props.item.threshold_hours >= 24 &&
                props.item.user_type == 'game'
              "
            >
              <span>Update Booked Assembly</span>
            </v-btn>
            <v-btn
              color="#38227A"
              dark
              class="text-capitalize px-7 mr-5"
              @click="toggleMessageModal({ show: true })"
              height="45px"
              style="border-radius: 10px; margin-left: 15px; font-size: 20px"
              v-if="
                $vuetify.breakpoint.smAndUp &&
                props.item.threshold_hours < 24 &&
                props.item.user_type == 'game'
              "
            >
              <span>Update Booked Assembly</span>
            </v-btn>
            <v-btn
              color="#38227A"
              dark
              class="text-capitalize px-7 mr-5"
              @click="
                toggleAssemblyModal1({
                  show: true,
                  type: 'edit',
                  id: props.item.id,
                })
              "
              height="24px"
              style="
                border-radius: 10px;
                margin-left: 10px;
                width: 60%;
                font-size: 8px;
              "
              v-if="
                $vuetify.breakpoint.xsOnly && props.item.user_type != 'game'
              "
            >
              <span>Update Booked Assembly</span>
            </v-btn>
            <v-btn
              color="#38227A"
              dark
              class="text-capitalize px-7 mr-5"
              @click="
                toggleAssemblyModal1({
                  show: true,
                  type: 'edit',
                  id: props.item.id,
                })
              "
              height="24px"
              style="
                border-radius: 10px;
                margin-left: 10px;
                width: 60%;
                font-size: 8px;
              "
              v-if="
                $vuetify.breakpoint.xsOnly &&
                props.item.threshold_hours >= 24 &&
                props.item.user_type == 'game'
              "
            >
              <span>Update Booked Assembly</span>
            </v-btn>
            <v-btn
              color="#38227A"
              dark
              class="text-capitalize px-7 mr-5"
              @click="toggleMessageModal({ show: true })"
              height="24px"
              style="
                border-radius: 10px;
                margin-left: 10px;
                width: 60%;
                font-size: 8px;
              "
              v-if="
                $vuetify.breakpoint.xsOnly &&
                props.item.threshold_hours < 24 &&
                props.item.user_type == 'game'
              "
            >
              <span>Update Booked Assembly</span>
            </v-btn>
            <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="toggleDeleteModal({ show: true, id: props.item.id })"
              v-if="props.item.user_type != 'game'"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="22"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </v-chip>
            <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="
                toggleViewAssemblyModal({ show: true, id: props.item.id })
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="22"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <span>View</span>
              </v-tooltip>
            </v-chip>
          </td>
        </tr>
      </template>
      <template v-slot:footer v-if="tabsSelectedValue === 'assembly'">
        <v-btn
          color="#38227A"
          dark
          class="text-capitalize px-7 mr-5"
          height="45px"
          style="
            border-radius: 10px;
            margin-left: 15px;
            width: 40%;
            font-size: 20px;
            margin-top: 1%;
            margin-bottom: 1%;
          "
          @click="
            toggleAllViewAssemblyModal({ show: true, id: $route.query.game_id })
          "
          v-if="tabsSelectedValue === 'assembly' && $vuetify.breakpoint.smAndUp"
        >
          <span>View Assembly Schedule</span>
        </v-btn>
        <v-btn
          color="#38227A"
          dark
          class="text-capitalize px-7 mr-5"
          height="40px"
          style="border-radius: 10px; width: 40%; font-size: 8px"
          @click="
            toggleAllViewAssemblyModal({ show: true, id: $route.query.game_id })
          "
          v-if="tabsSelectedValue === 'assembly' && $vuetify.breakpoint.xsOnly"
        >
          <span>View Assembly Schedule</span>
        </v-btn></template
      >
      <template
        v-slot:item="props"
        v-else-if="tabsSelectedValue === 'sponsors'"
      >
        <tr>
          <td class="text-center py-2">
            <div>
              <img :src="`${props.item.sponsor_logo}`" width="70" alt="" />
              <div class="ml-3">
                <div class="tableText">{{ props.item.sponsor_name }}</div>
                <div style="font-size: 12px; color: #2e6ce3">
                  {{ props.item.website }}
                </div>
              </div>
            </div>
          </td>

          <td class="text-center py-2">
            <div class="tableText">{{ props.item.contact_person_name }}</div>
            <div class="tableText">{{ props.item.contact_Person_phone }}</div>
            <div class="tableText">{{ props.item.contact_person_email }}</div>
          </td>
          <td class="text-center">
            <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="
                toggleSponsorModal({
                  show: true,
                  type: 'edit',
                  id: props.item.id,
                })
              "
              v-if="
                props.item.user_type == 'admin' ||
                props.item.user_type == 'Sales Representative' ||
                props.item.user_type == 'Event Support Staff'
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="22"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span class="action-btn">Edit</span>
              </v-tooltip>
            </v-chip>
            <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="toggleDeleteModal({ show: true, id: props.item.id })"
              v-if="
                props.item.user_type == 'admin' ||
                props.item.user_type == 'Sales Representative' ||
                props.item.user_type == 'Event Support Staff'
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="22"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </v-chip>
          </td>
        </tr>
      </template>
      <template v-slot:item="props" v-else-if="tabsSelectedValue === 'hotel'">
        <tr>
          <td class="text-center">
            <div style="font-size: 12px; color: #757575">
              {{ props.item.name }}
            </div>
          </td>
          <td class="text-center">
            <div style="font-size: 12px; color: #757575">
              {{ props.item.hotel_address }}
            </div>
          </td>
          <td class="text-center">
            <div style="font-size: 12px; color: #757575">
              {{ props.item.confirmation_number }}
            </div>
          </td>
          <td class="text-center">
            <div style="font-size: 12px; color: #757575">
              {{ props.item.checking_in_date_2 }}
            </div>
          </td>
          <td class="text-center">
            <div style="font-size: 12px; color: #757575">
              {{ props.item.checking_out_date_2 }}
            </div>
          </td>
          <td class="text-center">
            <div style="margin-left: 10%">
              <v-switch
                color="#067605"
                v-model="props.item.approved"
                @change="changeHotelStatus(props.item.approved, props.item.id)"
              >
                <template v-slot:label>
                  <div
                    :style="
                      props.item.approved ? 'color:#067605' : 'color:#8C8C8C'
                    "
                  >
                    <div v-if="props.item.approved">Approved</div>
                    <div v-else>Not Approved</div>
                  </div>
                </template>
              </v-switch>
            </div>
          </td>
          <td class="text-center">
            <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="
                toggleHotelInfoModal({
                  show: true,
                  type: 'edit',
                  id: props.item.id,
                })
              "
              v-if="
                props.item.user_type == 'admin' ||
                props.item.user_type == 'Sales Representative' ||
                props.item.user_type == 'Event Support Staff'
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="22"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span class="action-btn">Edit</span>
              </v-tooltip>
            </v-chip>
            <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="toggleDeleteModal({ show: true, id: props.item.id })"
              v-if="
                props.item.user_type == 'admin' ||
                props.item.user_type == 'Sales Representative' ||
                props.item.user_type == 'Event Support Staff'
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="22"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </v-chip>
          </td>
        </tr>
      </template>
      <template
        v-slot:item="props"
        v-else-if="tabsSelectedValue === 'volunteers'"
      >
        <tr>
          <td class="text-center">
            <div>{{ props.item.name }}</div>
          </td>
          <td class="text-center">
            <div>
              {{ props.item.email }}
            </div>
          </td>
          <td class="text-center">
            <div>
              {{ props.item.phone }}
            </div>
          </td>
          <td class="text-center">
            <div>
              {{ props.item.game_designation }}
            </div>
          </td>
          <td class="text-center py-4">
            <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="
                toggleDeleteModal({ show: true, id: props.item.committee_id })
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="22"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </v-chip>
            <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="resendInvitation(props.item)"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="25"
                  >
                    mdi-email
                  </v-icon>
                </template>
                <span>Resend Invitation</span>
              </v-tooltip>
            </v-chip>
          </td>
        </tr>
      </template>
      <template
        v-slot:item="props"
        v-else-if="tabsSelectedValue === 'homeTeam'"
      >
        <tr class="py-3">
          <td class="text-center">
            <div>{{ props.item.name }}</div>
          </td>
          <td class="text-center">
            <div>{{ props.item.email }}</div>
          </td>
          <td class="text-center">
            <div>{{ props.item.phone }}</div>
          </td>
          <td class="text-center">
            <div>{{ props.item.tshirt_size }}</div>
          </td>
          <td class="text-center">
            <div>{{ props.item.school }}</div>
          </td>
          <td class="text-center">
            <div>{{ props.item.school_type }}</div>
          </td>
          <td class="text-center">
            <div>{{ props.item.teacher }}</div>
          </td>
          <td class="text-center pt-3">
            <div class="d-flex justify-center">
              <v-switch
                style="margin-top: -2px"
                color="#067605"
                disabled
                v-model="props.item.is_confirmed"
              >
                <template v-slot:label>
                  <div
                    v-if="props.item.is_confirmed"
                    style="color: #067605; font-size: 14px"
                  >
                    Confirmed
                  </div>
                  <div v-else style="font-size: 14px; color: #8c8c8c">
                    Not Confirmed
                  </div>
                </template>
              </v-switch>
            </div>
          </td>
          <td class="text-center">
            <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="
                toggleHomeTeamModal({
                  show: true,
                  type: 'edit',
                  id: props.item.id,
                })
              "
              v-if="
                props.item.user_type == 'admin' ||
                props.item.user_type == 'Sales Representative' ||
                props.item.user_type == 'Event Support Staff'
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="22"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span class="action-btn">Edit</span>
              </v-tooltip>
            </v-chip>
            <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="toggleDeleteModal({ show: true, id: props.item.id })"
              v-if="
                props.item.user_type == 'admin' ||
                props.item.user_type == 'Sales Representative' ||
                props.item.user_type == 'Event Support Staff'
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="22"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </v-chip>
          </td>
        </tr>
      </template>
    </v-data-table>

    <div class="card-container mt-5">
      <!-- Heading Section -->
      <div class="card-heading">
        <h4 class="heading-text">Tickets Sold Information</h4>
        <h4 class="ticketTextHeading1" v-if="this.allseats">
          ({{ this.totalticketssold }}/{{ this.allseats }})
        </h4>
        <h4 class="ticketTextHeading1" v-else>
          ({{ this.totalticketssold }}/0)
        </h4>
        <button
          class="ticket_info_btn"
          @click="toggleTicketInfo({ show: true })"
          style="margin-left: 20px"
        >
          Update Ticket Info
        </button>
      </div>

      <div class="footer-buttons">
        <button class="btn" @click="toggleGameTableModal({ show: true })">
          <img
            src="@/assets/thingsToDo/table-icon.png"
            alt="Table Icon"
            class="icon"
          />
          View in Table
        </button>
        <button class="btn" @click="toggleGameGraphModal({ show: true })">
          <img
            src="@/assets/thingsToDo/graph-icon.png"
            alt="Graph Icon"
            class="icon"
          />
          View in Graph
        </button>
        <button
          class="btn"
          @click="toggleTicketRevenueModal({ show: true })"
         v-if="this.hostticketFlag" >
          <img
            src="@/assets/thingsToDo/graph-icon.png"
            alt="Graph Icon"
            class="icon"
          />
          Ticket Revenue Share
        </button>
      </div>
      <!-- Card Section -->
      <div class="card">
        <!-- Ticket URL -->
        <div class="info-row">
          <h5>Ticket URL:</h5>
          <a :href="pretixRedirectUrl" target="_blank">{{
            pretixRedirectUrl
          }}</a>
        </div>

        <!-- Facebook Event URL -->
        <div class="info-row">
          <h5>Facebook Event URL:</h5>
          <a :href="facebookUrl" target="_blank">{{ facebookUrl }}</a>
        </div>

        <!-- Boosted On Facebook -->
        <div class="info-row">
          <h5>Boosted On Facebook:</h5>
          <span v-if="boosted_on_fb" class="status-yes">YES</span>
          <span v-else class="status-no">NO</span>
        </div>

        <!-- Custom Video URL -->
        <div class="info-row">
          <h5>Custom Video URL:</h5>
          <a :href="videoUrl" target="_blank">{{ videoUrl }}</a>
        </div>
      </div>

      <v-simple-table
        class="elevation-8"
        style="margin-top: 2%; width: 100%; margin-bottom: 2%"
      >
        <template v-slot:default>
          <thead>
            <tr style="background: #e7e5ed">
              <th style="width: 80px"></th>
              <th
                class="textHeader text-center"
                v-for="(item, i) in filteredItems"
                :key="i"
              >
                {{ item.ticket_type_name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-center" style="width: 80px">
                <div>Online</div>
              </td>
              <td
                class="text-center"
                v-for="(item, i) in filteredItems"
                :key="i"
              >
                {{ item.total_online_ticket_sold }}
              </td>
            </tr>
            <tr>
              <td class="text-center" style="width: 80px">
                <div>Paper</div>
              </td>
              <td
                class="text-center"
                v-for="(item, i) in filteredItems"
                :key="i"
              >
                {{ item.total_offline_ticket_sold }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-simple-table
        class="elevation-8"
        style="margin-top: 2%; width: 100%; margin-bottom: 4%"
      >
        <template v-slot:default>
          <thead>
            <tr style="background: #e7e5ed">
              <th style="width: 80px"></th>
              <th
                class="textHeader text-center"
                v-for="(item, i) in filteredDoorItems"
                :key="i"
              >
                {{ item.ticket_type_name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-center" style="width: 80px">
                <div>Online</div>
              </td>
              <td
                class="text-center"
                v-for="(item, i) in filteredDoorItems"
                :key="i"
              >
                {{ item.total_online_ticket_sold }}
              </td>
            </tr>
            <tr>
              <td class="text-center" style="width: 80px">
                <div>Paper</div>
              </td>
              <td
                class="text-center"
                v-for="(item, i) in filteredDoorItems"
                :key="i"
              >
                {{ item.total_offline_ticket_sold }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
     
    </div>
    <assembly-schedual-modal1 @reload="getData()"></assembly-schedual-modal1>
    <sponsor-modal @reload="getData()"></sponsor-modal>
    <delete-modal @reload="deleteData()"></delete-modal>
    <assembly-schedual-modal @reload="getData()"></assembly-schedual-modal>
    <volunteer-modal @reload="getData()"></volunteer-modal>
    <home-team-modal @reload="getData()"></home-team-modal>
    <hotel-info-modal @reload="getData()"></hotel-info-modal>
    <!-- <note-modal></note-modal>
    <voucher-modal></voucher-modal> -->
    <game-graph-modal></game-graph-modal>
    <game-table-modal></game-table-modal>
    <ticket-info-modal></ticket-info-modal>
    <view-host-assembly-modal></view-host-assembly-modal>
    <view-all-host-assembly-modal></view-all-host-assembly-modal>
    <ticket-revenue-modal> </ticket-revenue-modal>
  </div>
</template>
<script>
import Axios from "@/api/BaseAxios";
import { GAME_TICKET_INFO_DETAIL_API } from "@/constants/APIUrls";
import { ROUTER_URL } from "@/constants/urls";
import {
  GAME_API_SPONSOR_LIST,
  GAME_API_SPONSOR_DELETE,
  ASSEMBLY_SCHEDUAL_API_LIST,
  ASSEMBLY_SCHEDUAL_API_DELETE,
  //VOLUNTEER_API_LIST,
  API_GAME_COMMITTEE_LIST,
  VOLUNTEER_API_DELETE,
  API_GAME_COMMITTEE_DELETE,
  API_HOME_TEAM_LIST,
  API_HOME_TEAM_DELETE,
  GAME_HOTEL_GET_LIST_API,
  //NOTE_SECTION_GET_LIST_API,
  //API_GET_LIST_GAME_VOUCHER,
  GAME_HOTEL_DELETE_API,
  //API_CREATE_VOUCHER,
  API_ADD_ASSEMBLY_CALENDAR,
  API_ADD_HOTEL_CALENDAR,
  API_GAME_HOST_TICKET_SOLD,
} from "@/constants/APIUrls";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "HostTicketInfo",
  components: {
    DeleteModal: () =>
      import("@/views/GameManagement/DeleteModal/DeleteModal.vue"),
    SponsorModal: () =>
      import(
        "@/views/GameManagement/GameDashboardVASH/SponsorModal/SponsorModal"
      ),
    AssemblySchedualModal: () =>
      import(
        "@/views/GameManagement/GameDashboardVASH/AssemblySchedualModal/AssemblySchedualModal"
      ),
    VolunteerModal: () =>
      import(
        "@/views/GameManagement/GameDashboardVASH/VolunteerModal/VolunteerModal"
      ),
    HomeTeamModal: () =>
      import(
        "@/views/GameManagement/GameDashboardVASH/HomeTeamModal/HomeTeamModal"
      ),
    HotelInfoModal: () =>
      import("@/views/GameManagement/HotelInfoModal/HotelInfoModal.vue"),
    // NoteModal: () => import("@/views/GameManagement/GameDashboardVASH/NoteModal/NoteModal"),
    // VoucherModal: () => import("@/views/GameManagement/GameDashboardVASH/VoucherModal/VoucherModal"),
    GameGraphModal: () =>
      import("@/views/GameManagement/TableGraphModal/GraphModal"),
    GameTableModal: () =>
      import("@/views/GameManagement/TableGraphModal/TableModal"),
    TicketInfoModal: () =>
      import("@/views/GameManagement/TicketInfo/AddTicketInfoModal.vue"),
    AssemblySchedualModal1: () =>
      import(
        "../GameDashboardVASH/AssemblySchedualModal/AssemblySchedualModal1"
      ),
    ViewHostAssemblyModal: () =>
      import(
        "../GameDashboardVASH/AssemblySchedualModal/ViewHostAssemblyModal"
      ),
    ViewAllHostAssemblyModal: () =>
      import(
        "../GameDashboardVASH/AssemblySchedualModal/ViewAllHostAssemblyModal"
      ),
      TicketRevenueModal: () =>
      import("@/views/GameManagement/Gamegraph/TicketRevenueModal"),
  },
  data() {
    return {
      adminAccess: localStorage.getItem("adminAccess"),
      loading: false,
      hostTicketSold: [],
      hostticketFlag: false,
      lastupdatedhosttime: "",
      lastupdatedhostdate: "",
      volunteersHeader: [
        {
          sortable: false,
          text: "Name",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Email",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Mobile",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Designation",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Actions",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
      ],
      AssemblyHeaders: [
        {
          sortable: false,
          text: "School Info",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Date/Time",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Point of contact",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },

        {
          sortable: false,
          text: "Actions",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
      ],
      sponsorsHeaders: [
        {
          sortable: false,
          text: "Sponsor Info",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Contact Person info",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Actions",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
      ],
      homeTeamHeaders: [
        {
          sortable: false,
          text: "Name",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Email",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Mobile",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Tshirt size",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "School/Organization",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "School Type",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Teacher",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Confirmed",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Actions",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
      ],
      hotelHeaders: [
        {
          sortable: false,
          text: "Name",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Address",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Confirmation Number",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Check in date",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Check out date",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Status",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Action",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
      ],
      selected: 0,
      items: [],
      assemblyItems: [],
      hotelItems: [],
      sponsorItems: [],
      committeeItems: [],
      hometeamItems: [],
      activeStep: 0,
      progress_status: [1, 2],
      stepNames: [
        {
          name: "Assembly Schedule",
          id: 0,
          value: "assembly",
          activeIcon: require("@/assets/thingsToDo/assemblyactive.png"),
          inactiveIcon: require("@/assets/thingsToDo/assemblyicon (1).png"),
          completedIcon: require("@/assets/thingsToDo/confirmimg.png"),
        },
        {
          name: "Hotel",
          id: 1,
          value: "hotel",
          activeIcon: require("@/assets/thingsToDo/hotelactive.png"),
          inactiveIcon: require("@/assets/thingsToDo/hotelicon (1).png"),
          completedIcon: require("@/assets/thingsToDo/confirmimg.png"),
        },
        {
          name: "Committee",
          id: 2,
          value: "volunteers",
          activeIcon: require("@/assets/thingsToDo/committeeactive.png"),
          inactiveIcon: require("@/assets/thingsToDo/commiteeicon (1).png"),
          completedIcon: require("@/assets/thingsToDo/confirmimg.png"),
        },
        {
          name: "Sponsors",
          id: 3,
          value: "sponsors",
          activeIcon: require("@/assets/thingsToDo/sponsoractive.png"),
          inactiveIcon: require("@/assets/thingsToDo/sponsoricon (1).png"),
          completedIcon: require("@/assets/thingsToDo/confirmimg.png"),
        },
        {
          name: "Home Team",
          id: 4,
          value: "homeTeam",
          activeIcon: require("@/assets/thingsToDo/hometeamactive.png"),
          inactiveIcon: require("@/assets/thingsToDo/teamicon (1).png"),
          completedIcon: require("@/assets/thingsToDo/confirmimg.png"),
        },
      ],
      totalticketssold: "",
      allseats: "",
      pretixRedirectUrl: "",
      facebookUrl: "",
      boostedonFlag: true,
      boosted_on_fb: false,
      videoUrl: "",
      ticketitems: [],
    };
  },
  computed: {
    ...mapGetters({
      //selectGameData: "gameManagement/getGame",
    }),
    filteredItems() {
      return this.ticketitems.filter(
        (item) => !item.ticket_type_name.includes("Door")
      );
    },
    filteredDoorItems() {
      return this.ticketitems.filter(
        (item) => !item.ticket_type_name.includes("Advance")
      );
    },
    selectedHeaders() {
      let value = this.stepNames[this.selected].value;
      switch (value) {
        case "volunteers":
          return { header: this.volunteersHeader, text: "Add Committee" };
        case "assembly":
          return {
            header: this.AssemblyHeaders,
            text: "Add Assembly Schedule",
          };
        case "sponsors":
          return { header: this.sponsorsHeaders, text: "Add Sponsor" };
        case "homeTeam":
          return { header: this.homeTeamHeaders, text: "Add Team Member" };
        case "hotel":
          return { header: this.hotelHeaders, text: "Add Hotel" };
        // case "voucher":
        //   return { header: this.voucherHeaders, text: "Add Voucher" };
        default:
          return {
            header: this.AssemblyHeaders,
            text: "Add Assembly Schedule",
          };
      }
    },
    tabsSelectedValue() {
      return this.stepNames[this.selected].value;
    },
    allSchoolsNotNull() {
      // Check if all records have a non-null "school" column
      return this.items.every((item) => item.school !== null);
    },
  },
  created() {
    this.$root.$refs.HostTicketInfo = this;
  },
  watch: {
    selectStep(step) {
      this.selected = step.id;
      this.activeStep = step.id; // Set the active step
      this.tabsSelectedValue = step.value; // Map the step to its corresponding tab value
      this.getData();
    },
    assemblyItems: "updateRowCounts",
    hotelItems: "updateRowCounts",
    sponsorItems: "updateRowCounts",
    committeeItems: "updateRowCounts",
    hometeamItems: "updateRowCounts",
  },
  methods: {
    ...mapActions({
      toggleSponsorModal: "gameManagement/toggleSponsor",
      toggleDeleteModal: "deleteManagement/toggleModal",
      toggleAssemblyModal: "gameManagement/toggleAssembly",
      toggleVolunteerModal: "gameManagement/toggleVolunteer",
      showToast: "snackBar/showToast",
      toggleHomeTeamModal: "gameManagement/toggleHomeTeam",
      toggleHotelInfoModal: "gameManagement/toggleHotelInfoModal",
      //toggleNoteModal: "gameManagement/toggleNoteModal",
      //toggleVoucherModal: "gameManagement/toggleVoucherModal",
      toggleGameGraphModal: "gameManagement/toggleGameGraphModal",
      toggleAssemblyModal1: "gameManagement/toggleAssembly1",
      toggleGameTableModal: "gameManagement/toggleGameTableModal",
      toggleTicketInfo: "gameManagement/toggleTicketInfo",
      toggleViewAssemblyModal: "gameManagement/toggleViewAssemblyModal",
      toggleAllViewAssemblyModal: "gameManagement/toggleAllViewAssemblyModal",
      toggleTicketRevenueModal: "gameManagement/toggleTicketRevenueModal",
    }),
    openModal() {
      switch (this.tabsSelectedValue) {
        case "volunteers":
          this.toggleVolunteerModal({ show: true, type: "add" });
          break;
        case "assembly":
          this.toggleAssemblyModal({ show: true, type: "add" });
          break;
        case "sponsors":
          this.toggleSponsorModal({ show: true, type: "add" });
          break;
        case "homeTeam":
          this.toggleHomeTeamModal({ show: true, type: "add" });
          break;
        case "hotel":
          this.toggleHotelInfoModal({ show: true, type: "add" });
          break;
        // case "note":
        //   this.toggleNoteModal({ show: true, type: "add" });
        //   break;
        // case "voucher":
        //   this.toggleVoucherModal({ show: true, type: "add" });
        //   break;
        default:
          this.toggleAssemblyModal({ show: true, type: "add" });
          break;
      }
    },
    getTicketInfo() {
      const successHandler = (res) => {
        this.ticketitems = res.data.ticket_detail;
        // this.profit = res.data.profit_flag;
        // this.profit_amount = res.data.ticket_profit;
        // this.last_updateBy = res.data.last_updated_by_name;
        // this.last_updatedDate = res.data.last_updated_on.date;
        // this.last_updatedTime = res.data.last_updated_on.time;
        // this.totalonlinesold=res.data.total_sold_online;
        this.totalticketssold = res.data.total_tickets_sold;
        this.pretixRedirectUrl = res.data.pretix_redirect_url;
        this.facebookUrl = res.data.facebook_url;
        this.boosted_on_fb = res.data.boosted_on_fb;
        this.boostedonFlag = true;
        this.videoUrl = res.data.video_url;
        if (res.data.all_seats) {
          this.allseats = res.data.all_seats;
        }
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      const finallyHandler = () => {
        this.$emit("reload");
      };
      let formData = {};
      formData["game_id"] = this.$route.query.game_id;
      Axios.request_GET(
        GAME_TICKET_INFO_DETAIL_API,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    selectStep(step) {
      this.selected = step.id;
      this.activeStep = step.id; // Set the active step
      this.tabsSelectedValue = step.value; // Map the step to its corresponding tab value
      this.getData(); // Fetch the data for the selected step
    },
    goToGameResourcePage() {
      this.$router.push({
        name: ROUTER_URL.gamePanel.children.gameresource.name,
        query: { ...this.$route.query, game_id: this.$route.query.game_id },
      });
    },
    getData() {
      this.items = [];
      switch (this.tabsSelectedValue) {
        case "volunteers":
          this.getCommitteeData();
          break;
        case "assembly":
          this.getAssemblyData();
          break;
        case "sponsors":
          this.getSponsorsData();
          break;
        case "homeTeam":
          this.getHomeTeamData();
          break;
        case "hotel":
          this.getHotelData();
          break;
        default:
          this.getAssemblyData();
        // case "voucher":
        //   this.getVoucherData();
        //   break;
      }
    },

    deleteData() {
      switch (this.tabsSelectedValue) {
        case "volunteers":
          this.deleteCommitteeData();
          break;
        case "assembly":
          this.deleteAssemblyData();
          break;
        case "sponsors":
          this.deleteSponsorsData();
          break;
        case "homeTeam":
          this.deleteHomeTeamData();
          break;
        case "hotel":
          this.deleteHotelData();
          break;
      }
    },
    // getVolunteersData() {
    //   this.loading = true;
    //   const successHandler = (res) => {
    //     this.items = res.data.volunteer_detail;
    //     this.loading = false;
    //   };
    //   const failureHandler = (res) => {
    //     console.log(res);
    //     this.loading = false;
    //   };
    //   let formData = {};
    //   formData["game_id"] = this.$route.query.game_id;
    //   Axios.request_GET(
    //     VOLUNTEER_API_LIST,
    //     formData,
    //     {},
    //     successHandler,
    //     failureHandler,
    //     false
    //   );
    // },
    getCommitteeData() {
      this.loading = true;
      const successHandler = (res) => {
        this.items = res.data.team_list;
        this.committeeItems = res.data.team_list;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["game_id"] = this.$route.query.game_id;
      Axios.request_GET(
        API_GAME_COMMITTEE_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getHotelData() {
      this.loading = true;
      const successHandler = (res) => {
        this.items = res.data.hotel_list;
        this.hotelItems = res.data.hotel_list;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["game_id"] = this.$route.query.game_id;
      Axios.request_GET(
        GAME_HOTEL_GET_LIST_API,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    // getNoteData() {
    //   this.loading = true;
    //   const successHandler = (res) => {
    //     this.items = res.data.note_list;
    //     this.loading = false;
    //   };
    //   const failureHandler = (res) => {
    //     console.log(res);
    //     this.loading = false;
    //   };
    //   let formData = {};
    //   formData["game_id"] = this.$route.query.game_id;
    //   Axios.request_GET(
    //     NOTE_SECTION_GET_LIST_API,
    //     formData,
    //     {},
    //     successHandler,
    //     failureHandler,
    //     false
    //   );
    // },
    // getVoucherData() {
    //   this.loading = true;
    //   const successHandler = (res) => {
    //     this.items = res.data.voucher_list;
    //     this.loading = false;
    //   };
    //   const failureHandler = (res) => {
    //     console.log(res);
    //     this.loading = false;
    //   };
    //   let formData = {};
    //   formData["game_id"] = this.$route.query.game_id;
    //   Axios.request_GET(
    //     API_GET_LIST_GAME_VOUCHER,
    //     formData,
    //     {},
    //     successHandler,
    //     failureHandler,
    //     false
    //   );
    // },
    getAssemblyData() {
      this.loading = true;
      const successHandler = (res) => {
        this.items = res.data.assemble_list;
        this.assemblyItems = res.data.assemble_list;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["game_id"] = this.$route.query.game_id;
      Axios.request_GET(
        ASSEMBLY_SCHEDUAL_API_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getSponsorsData() {
      this.loading = true;
      const successHandler = (res) => {
        this.items = res.data.sponsor_list;
        this.sponsorItems = res.data.sponsor_list;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["game_id"] = this.$route.query.game_id;
      Axios.request_GET(
        GAME_API_SPONSOR_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getHomeTeamData() {
      this.loading = true;
      const successHandler = (res) => {
        // console.log(res.data)
        this.items = res.data.home_team_list;
        this.hometeamItems = res.data.home_team_list;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["game_id"] = this.$route.query.game_id;
      Axios.request_GET(
        API_HOME_TEAM_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    deleteVolunteersData() {
      const successHandler = (res) => {
        console.log(res);
        this.showToast({
          message: "Deleted Successfully",
          color: "s",
        });
      };
      const failureHandler = (res) => {
        console.log(res);
        this.showToast({
          message: res,
          color: "e",
        });
      };
      const finallyHandler = () => {
        this.getData();
      };
      let formData = {};
      formData["id"] = this.$store.state.deleteManagement.deleteModal.deleteID;
      Axios.request_DELETE(
        VOLUNTEER_API_DELETE,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    deleteCommitteeData() {
      const successHandler = (res) => {
        console.log(res);
        this.showToast({
          message: "Deleted Successfully",
          color: "s",
        });
      };
      const failureHandler = (res) => {
        console.log(res);
        this.showToast({
          message: res,
          color: "e",
        });
      };
      const finallyHandler = () => {
        this.getData();
      };
      let formData = {};
      formData["id"] = this.$store.state.deleteManagement.deleteModal.deleteID;
      Axios.request_DELETE(
        API_GAME_COMMITTEE_DELETE,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    deleteAssemblyData() {
      const successHandler = (res) => {
        console.log(res);
        this.showToast({
          message: "Deleted Successfully",
          color: "s",
        });
      };
      const failureHandler = (res) => {
        this.showToast({
          message: res,
          color: "e",
        });
      };
      const finallyHandler = () => {
        this.getData();
      };
      let formData = {};
      formData["id"] = this.$store.state.deleteManagement.deleteModal.deleteID;
      Axios.request_DELETE(
        ASSEMBLY_SCHEDUAL_API_DELETE,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    deleteSponsorsData() {
      console.log("spo delete");
      console.log(this.$store.state.deleteManagement.deleteModal.deleteID);
      const successHandler = (res) => {
        console.log(res);
        this.showToast({
          message: "Deleted Successfully",
          color: "s",
        });
      };
      const failureHandler = (res) => {
        console.log(res);
        this.showToast({
          message: res,
          color: "e",
        });
      };
      const finallyHandler = () => {
        this.getData();
      };
      let formData = {};
      formData["id"] = this.$store.state.deleteManagement.deleteModal.deleteID;
      Axios.request_DELETE(
        GAME_API_SPONSOR_DELETE,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    deleteHomeTeamData() {
      console.log("delete");
      const successHandler = (res) => {
        console.log(res);
        this.showToast({
          message: "Deleted Successfully",
          color: "s",
        });
      };
      const failureHandler = (res) => {
        console.log(res);
        this.showToast({
          message: res,
          color: "e",
        });
      };
      const finallyHandler = () => {
        this.getData();
      };
      let formData = {};
      formData["id"] = this.$store.state.deleteManagement.deleteModal.deleteID;
      Axios.request_DELETE(
        API_HOME_TEAM_DELETE,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    deleteHotelData() {
      console.log("delete");
      const successHandler = (res) => {
        console.log(res);
        this.showToast({
          message: "Deleted Successfully",
          color: "s",
        });
      };
      const failureHandler = (res) => {
        console.log(res);
        this.showToast({
          message: res,
          color: "e",
        });
      };
      const finallyHandler = () => {
        this.getData();
      };
      let formData = {};
      formData["id"] = this.$store.state.deleteManagement.deleteModal.deleteID;
      Axios.request_DELETE(
        GAME_HOTEL_DELETE_API,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    pageReload() {
      location.reload();
    },
    // createVoucher() {
    //   this.formLoading = true;
    //   const successHandler = (res) => {
    //     console.log(res.data);
    //     this.formLoading = false;
    //     this.showToast({
    //       message: "Created Successfully",
    //       color: "s",
    //     });
    //     location.reload();
    //   };
    //   const failureHandler = (res) => {
    //     console.log(res);
    //     this.formLoading = false;
    //   };
    //   let formData = {};
    //   formData["game_id"] = this.$route.query.game_id;
    //   Axios.request_POST(
    //     API_CREATE_VOUCHER,
    //     formData,
    //     {},
    //     successHandler,
    //     failureHandler,
    //     false
    //   );
    // },
    resendInvitation(item) {
      const successHandler = () => {
        this.showToast({
          message: "Invitation sent",
          color: "s",
        });
      };

      const failureHandler = () => {};
      let formData = {};
      formData["user_id"] = item.id;
      formData["id"] = this.$route.query.game_id;
      Axios.request_GET(
        "/game/resend_email/",
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },

    changeStatus(status, id) {
      console.log(status, id);
      const successHandler = (res) => {
        console.log(res.data, "status changed");
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      let formData = {};
      formData["id"] = id;
      formData["status"] = status;
      Axios.request_PATCH(
        API_ADD_ASSEMBLY_CALENDAR,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    changeHotelStatus(status, id) {
      console.log(status, id);
      const successHandler = (res) => {
        console.log(res.data, "status changed");
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      let formData = {};
      formData["id"] = id;
      formData["status"] = status;
      Axios.request_PATCH(
        API_ADD_HOTEL_CALENDAR,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getIconForStep(step, itemCount) {
      if (this.activeStep === step.id) {
        // If the step is active, use the active icon
        return step.activeIcon;
      } else if (itemCount > 0) {
        // If the step has items, use the completed icon
        return step.completedIcon;
      } else {
        // Otherwise, use the inactive icon
        return step.inactiveIcon;
      }
    },
    getRowCount(step) {
      switch (step.value) {
        case "assembly":
          return this.assemblyItems?.length || 0;
        case "hotel":
          return this.hotelItems?.length || 0;
        case "volunteers":
          return this.committeeItems?.length || 0;
        case "sponsors":
          return this.sponsorItems?.length || 0;
        case "homeTeam":
          return this.hometeamItems?.length || 0;
        default:
          return 0;
      }
    },
    updateRowCounts() {
      this.stepNames.forEach((step) => {
        step.rowCount = this.getRowCount(step); // Store counts directly on step objects
      });
    },
    getHostTicketData() {
      const successHandler = (res) => {
        this.hostTicketSold = res.data.order_info;
        if (this.hostTicketSold.length != 0) {
          this.hostticketFlag = res.data.data_available;
          this.lastupdatedhosttime = res.data.last_update_on.time;
          this.lastupdatedhostdate = res.data.last_update_on.date;
        }
        //alert(this.hostticketFlag);
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      const finallyHandler = () => {
        this.$emit("reload");
      };
      let formData = {};
      formData["game_id"] = this.$route.query.game_id;
      Axios.request_GET(
        API_GAME_HOST_TICKET_SOLD,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
  },
  mounted() {
    this.getHotelData();
    this.getCommitteeData();
    this.getHomeTeamData();
    this.getSponsorsData();
    this.tabsSelectedValue = "assembly";
    this.getAssemblyData();
    this.getTicketInfo();
    this.getHostTicketData();
  },
};
</script>
<style scoped>
span.v-chip {
  border-radius: 4px;
  margin-left: 12px;
}
.v-data-table.elevation-8.v-data-table--fixed-header.v-data-table--has-top.theme--light {
  border-radius: 6px;
}
.tableText {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 145%;
  /* identical to box height, or 25px */

  color: #1d1d1d;
}
</style>
<style scoped>
.tableHeader-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 17px !important;
  line-height: 161.5% !important;
  color: #1d1d1d !important;
  padding: 0px 4px !important;
}
.tableHeader-bg {
  background: #e7e5ed !important;
}
span.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
  height: 45px;
  width: 38px;
  cursor: pointer;
}
.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}
.ticketTextHeading {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 140%;
  color: #2c1963;
}
/* .ticketTextLine{
      width: 59px;
      height: 5px;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: 10px;
      background: #2c1963;
      border-radius: 23px;
  } */
.header-container {
  display: flex;
  justify-content: space-evenly; /* Align 'Things to do' and stepper in one row */
  align-items: center; /* Vertically center-align the elements */
  margin-bottom: 10px; /* Add spacing below */
}

.stepper-wrapper.row {
  display: flex;
  align-items: center;
  gap: 20px; /* Space between stepper items */
  position: relative;
}

.stepper-item-wrapper {
  display: flex;
  align-items: center;
}

.stepper-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.stepper-item:not(:last-child)::before {
  content: "";
  position: absolute;
  top: 25px;
  left: 70%;
  width: 64px;
  height: 2px;
  border-top: 2px dashed #ccc;
  z-index: 0;
}

.step-counter img {
  width: 50px;
  height: 50px;
}
.step-counter {
  z-index: 2;
}

.card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 96%;
  padding: 0px 40px;
  margin-top: -40px;
  margin-left: 2%;
}

/* Heading Section Styling */
.card-heading {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.heading-text {
  font-size: 32px;
  font-weight: 900;
  padding-right: 20px;
  color: #2c1963; /* Purple color */
}

.ticket-status {
  font-size: 18px;
  font-weight: bold;
  color: #555;
  padding-right: 20px;
}

/* Card styling */
.card {
  width: 100%;
  /* max-width: 1000px; */
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  font-family: Arial, sans-serif;
  padding: 15px;
  margin-bottom: 25px;
  margin-top: 10px;
}

/* Info Row Styling */
.info-row {
  display: flex;
  /* justify-content: space-evenly;
    align-items: center; */
  margin: 10px 0;
}

.info-row h5 {
  margin: 5px;
  font-size: 16px;
  color: #333;
}

.info-row a {
  color: #007bff;
  text-decoration: none;
  margin-left: 10px;
}

.info-row a:hover {
  text-decoration: underline;
}

/* Status Colors */
.status-yes {
  color: green;
  font-weight: bold;
}

.status-no {
  color: red;
  margin-top: 5px;
  font-weight: bold;
}

/* Footer Buttons */
.footer-buttons {
  display: flex;
  justify-content: left;
  gap: 20px; /* Space between buttons */
  margin-top: 10px;
  margin-bottom: 10px;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  background-color: #2c1963; /* Purple button */
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn:hover {
  background-color: #2c0e52; /* Darker purple */
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 10px; /* Space between icon and text */
}

.ticket_info_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  background-color: #ee1f51; /* red button */
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.v-data-table.elevation-8.v-data-table--fixed-header.v-data-table--has-top.theme--light {
  border-radius: 6px;
  width: 90%;
  margin-left: 5%;
}

.speech-bubble {
  position: relative;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  max-width: 985px;
  font-size: 14px;
  line-height: 1.5;
  margin: 16px auto;
}

.speech-bubble::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 20px;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-bottom-color: #ffffff;
  border-top: 0;
  margin-left: 405px;
  margin-bottom: -1px;
}

.resources-container {
  display: flex;
  align-items: center;
  padding: 25px 90px;
  gap: 10px;
}

.resources-text {
  display: flex;
  align-items: center;
  font-family: Arial, sans-serif;
  font-size: 35px;
  color: #38227a;
}

.bold-text {
  font-size: 30px;
  font-weight: bold;
  color: #38227a; /* Purple color */
}

.dashed-line {
  margin: 0 10px;
  border-top: 5px solid #2c1963;
  width: 152px;
  height: 1px;
}

.normal-text {
  margin-left: 10px;
  color: #38227a;
  font-size: 27px;
  font-weight: bold;
  display: inline;
}

.playbook-link {
  margin-left: 5px;
  color: #d62c53 !important; /* Red color */
  text-decoration: underline;
  font-weight: bold;
  font-size: 30px;
}

.textHeader {
  font-size: 14px;
}

.step-name {
  font-size: 18px;
  font-weight: 500;
  color: #38227a;
}
.textboxP {
  padding: 10px;
  border: 1px solid #38227a;
  box-shadow: 16px;
  margin: 20px;
  border-radius: 7px;
}

@media (max-width: 768px) {
  .resources-container {
    flex-direction: column; /* Stack items vertically for smaller screens */
    text-align: center;
  }

  .resources-text {
    font-size: 30px;
  }

  .header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .step-heading {
    padding: 10px;
    gap: 20px; /* Adds spacing between elements */
  }

  .header-container {
    text-align: left;
  }

  .stepper-wrapper {
    display: flex;
    flex-direction: row;
    gap: 0;
    width: 100%;
  }
  .row {
    flex-wrap: nowrap !important;
  }
  .card-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .heading-text {
    font-size: 25px;
  }
  .resources-text {
    display: flex;
    flex-direction: column; /* Stack elements on small devices */
    gap: 10px; /* Adjust gap for smaller screens */
    align-items: center;
  }

  .dashed-line {
    display: none; /* Hide the line on small screens */
  }
  .bold-text {
    font-size: 40px;
    font-weight: bold;
  }
  .normal-text {
    text-align: center;
    font-size: 25px;
  }
  .step-name {
    font-size: 10px;
    font-weight: 500;
    color: #38227a;
    padding: 5px;
  }
  .ticketTextHeading {
    font-size: 25px;
    margin-bottom: 15px;
    margin-top: -7px;
  }
  .step-counter img {
    width: 30px;
    height: 30px;
    z-index: 2;
  }
  .stepper-item:not(:last-child)::before {
    content: "";
    position: absolute;
    top: 13px;
    left: 74%;
    width: 41px;
    height: 2px;
    border-top: 2px dashed #ccc;
    z-index: 0;
  }

  .stepper-wrapper.row {
    display: flex !important;
    gap: 20px !important;
    align-items: normal !important;
  }

  .v-data-table.elevation-8.v-data-table--fixed-header.v-data-table--has-top.theme--light {
    border-radius: 6px;
    width: 90%;
    margin-left: 5%;
  }

  .v-main__wrap {
    flex: 1 1 auto;
    max-width: 100%;
    position: relative;
  }
  .tableText {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 145%;
    /* identical to box height, or 25px */

    color: #1d1d1d;
  }
}
.footerStyle {
  font-family: Lato;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
}
</style>
