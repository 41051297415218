<style>
.v-application .white--text {
  color: #000 !important;
  caret-color: #000 !important;
  font-weight: bolder;
}

.v-card__text {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0.0071428571em;
  background: #fff;
}

.students {
  display: flex;
  list-style: none;
  padding: 0;
}
.student ~ .student::before {
  content: ", ";
}
.theme--dark.v-sheet {
  background-color: #1e1e1e;
  border-color: #1e1e1e;
  color: black;
}
.v-calendar .v-event {
  white-space: unset !important;
  overflow-wrap: break-word;
}

@media (max-width: 1281px) {
.v-calendar .v-event {
line-height: 10px  !important;;
}
}
@media (max-width: 1281px) {
.v-event.v-event-start.v-event-end.white--text {
  height: auto !important;
  font-size:5px !important;
}
}
@media (min-width: 1281px) {
.v-event.v-event-start.v-event-end.white--text {
  height: auto !important;
  font-size:12px !important;
}
}
@media (max-width: 1281px) {
  .v-calendar-monthly.v-calendar-weekly.v-calendar.theme--light.v-calendar-events {
    width: 168% !important;
  }
}
@media (min-width: 960px) {
  .v-toolbar__title {
    margin: 25px;
  }
}

@media (max-width: 1281px) {
  .v-application .px-2 {
    padding-left: 0px !important;
  }
}
.column-container {
  column-count: 3; /* Set the number of columns */
  column-gap: 10px; /* Adjust the gap between columns as needed */
  max-width: 600px; /* Adjust the max width of the container as needed */
}
</style>
<template>
  <v-app>
    <div>

       <v-row v-if="$vuetify.breakpoint.smAndUp">
      <v-sheet
        tile
        class="col-md-4 col-lg-4 col-sm-12 col-xs-12"
        style="text-align: center"
      >
        <!-- Dropdown for Month, Day, Week, List -->
       
      </v-sheet>
      <v-sheet
        tile
        class="col-md-4 col-lg-3 col-sm-12 col-xs-12 text-center"
        style="padding: 1px"
      >
        <v-toolbar-title v-if="$refs.calendar">
          <v-icon
            @click="$refs.calendar.prev()"
            style="color: #38227a; cursor: pointer"
            >mdi-chevron-left</v-icon
          >
          <b>{{ $refs.calendar.title }}</b>
          <v-icon
            @click="$refs.calendar.next()"
            style="color: #38227a; cursor: pointer"
            >mdi-chevron-right</v-icon
          >
        </v-toolbar-title>
      </v-sheet>
      <v-sheet
        tile
        class="col-md-4 col-lg-5 col-sm-12 col-xs-12"
        style="padding: 1px; padding-top: 1%; text-align: center"
      >
        <v-btn
          color="#38227A"
          dark
          class="px-2"
          style="border-radius: 10px; margin: 10px 5px"
          @click="toggleAddTaskModal({ show: true, type: 'add' })"
        >
          <span style="margin-left: 5px; text-transform: capitalize"
            >Task +</span
          >
        </v-btn>
      
      </v-sheet>
    </v-row>

    
     <v-row v-if="$vuetify.breakpoint.xsOnly">
      <v-sheet tile class="col-md-4 col-lg-4 col-sm-12 col-xs-12">
        <!-- Dropdown for Month, Day, Week, List -->
        <v-btn
          color="#38227A"
          dark
          class="px-2"
          style="
            border-radius: 10px;
            margin: 10px 5px;
            font-size: 7px;
            height: 18px;
            min-width: 50px;
          "
          @click="toggleAddTaskModal({ show: true, type: 'add' })"
        >
          <span style="margin-left: 5px; text-transform: capitalize"
            >Task +</span
          >
        </v-btn>
      </v-sheet>
      <v-sheet
        tile
        class="col-md-4 col-lg-3 col-sm-12 col-xs-12 text-center"
        style="padding: 1px"
      >
        <v-toolbar-title v-if="$refs.calendar">
          <v-icon
            @click="$refs.calendar.prev()"
            style="color: #38227a; cursor: pointer; font-size: 14px"
            >mdi-chevron-left</v-icon
          >
          <b style="font-size: 14px">{{ $refs.calendar.title }}</b>
          <v-icon
            @click="$refs.calendar.next()"
            style="color: #38227a; cursor: pointer; font-size: 14px"
            >mdi-chevron-right</v-icon
          >
        </v-toolbar-title>
      </v-sheet>
    </v-row>
      <div class="ma-4" style="height: 50%">
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card min-width="auto" style="height: 400px; background: #ffffff">
            <v-toolbar
              color="#F9B4F6"
              dark
              v-if="this.selectedEvent.color == '#F9B4F6'"
            >
              <v-toolbar-title>
                {{ selectedEvent.name }}
              </v-toolbar-title>
            </v-toolbar>
            <v-toolbar
              color="#8c6ee39e"
              dark
              v-else
              if="this.selectedEvent.color == '#8c6ee39e'"
            >
              <v-toolbar-title>
                {{ selectedEvent.name }}
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <div
                class="text-center"
                v-if="this.selectedEvent.color == '#F9B4F6'"
              >
                <div style="text-align: justify; font-size: 16px">
                  <hr
                    style="
                    height: 2px;
                    border-width: 0;'
                    color: gray;
                    background-color: gray;
                  "
                  />
                  {{ this.ptoDetail.start_date_str }}
                   <v-icon
                  dark
                  class="icons"
                  color="#6B6B6B"
                  size="22"
                  @click="
                    toggleAddTaskModal({
                      show: true,
                      type: 'edit',
                      id: selectedEvent.id,
                    })
                  "
                >
                  mdi-pencil
                </v-icon>
                <v-icon size="18" color="red" @click="deleteTaskData()"
                  >mdi-delete</v-icon
                >
                  <hr
                    style="
                      height: 2px;
                      border-width: 0;
                      color: gray;
                      background-color: gray;
                    "
                  />

                  <br />
                  <strong>Category: </strong>Task
                  <br />
                  <br />
                  <strong>Description: </strong> {{ this.ptoDetail.note }}
                  <hr
                    style="
                      height: 2px;
                      border-width: 0;
                      color: gray;
                      background-color: gray;
                    "
                  />
                </div>
              </div>
              <div
                class="text-center"
                v-if="this.selectedEvent.color == '#8c6ee39e'"
              >
                <div
                style="text-align: justify; font-size: 16px"
                v-if="this.chekinclude.includes('KO')"
              >
                <hr
                  style="
                    height: 2px;
                    border-width: 0;'
                    color: gray;
                    background-color: gray;
                  "
                />
                {{ this.campaignData.kick_off_date_str_3 }}
                <v-icon
                  dark
                  class="icons"
                  color="#6B6B6B"
                  size="22"
                  @click="editCampaign()"
                >
                  mdi-pencil
                </v-icon>
                <hr
                  style="
                    height: 2px;
                    border-width: 0;'
                    color: gray;
                    background-color: gray;
                  "
                />
                <br />
                <div v-if="this.campaignData.kick_off_player_list != null">
                  <tr class="students">
                    <strong>Players: </strong>
                    <td
                      v-for="(item, i) in kickoffplayeritems"
                      :key="i"
                      v-text="item.name"
                      class="student show"
                    ></td>
                  </tr>
                </div>
                <br>
                <strong>Category:</strong> Wizfit
                <br />
                <br />
                <strong>Location:</strong> {{ this.campaignData.organization_name }}
                  <br />  <br />
                  <strong>Address:</strong> {{ this.campaignData.shipping_address }}
                  <br />  <br />
                  <strong>Time:</strong> {{ this.campaignData.kick_off_start_time }} - {{ this.campaignData.kick_off_end_time }}
                  <br />  <br />
                  <strong>Student Enrollment:</strong>{{ this.campaignData.total_student}}/{{ this.campaignData.school_info_total_student }}
                  <br />  <br />
              <strong>Note: </strong>
                <div v-html=" this.campaignData.notes"></div> <br />
                <br />  <br />
                  <strong>Money Raised:</strong> {{ this.campaignData.original_total_fund_raised_50 }}
                  <br>
                  <strong>Financial Goal:</strong> {{ this.campaignData.total_goal_50 }}
                     <br />  <br />
                <strong>Description: </strong><div v-html=this.campaignData.special_message></div>
                <br />  <br />
                  <strong>School Goals And Incentives:</strong><br>
                   Low Goal Condition:&nbsp;&nbsp;{{ this.campaignData.low_goal }}
                   <br>
                   Low Goal Purpose:&nbsp;&nbsp;{{ this.campaignData.low_goal_purpose }}
                    <br>
                   Middle Goal Condition:&nbsp;&nbsp;{{ this.campaignData.middle_goal}}
                    <br>
                   Middle Goal Purpose:&nbsp;&nbsp;{{ this.campaignData.middle_goal_purpose }}
                    <br>
                   Top Goal Condition:&nbsp;&nbsp;{{ this.campaignData.top_goal}}
                    <br>
                   Top Goal Purpose:&nbsp;&nbsp;{{ this.campaignData.top_goal_purpose }}
                   <br />  <br />
                  <strong>Other Incentives:</strong>  <div v-html="this.campaignData.other_incentive"></div>
                   <br />  <br />
                      <strong style="font-size: 20px; text-decoration: underline"
                  >Contact Info:</strong
                >
                   <br />
                  <strong>Name:</strong>{{ this.campaignData.host_name }} 
                   <br />
                  <strong>Email:</strong> {{ this.campaignData.host_email }} 
                   <br />
                  <strong>Phone No.:</strong> 
                <hr
                  style="
                    height: 2px;
                    border-width: 0;'
                    color: gray;
                    background-color: gray;
                  "
                />
              </div>
                 <div
                style="text-align: justify; font-size: 16px"
                v-if="this.chekinclude.includes('GB')"
              >
                <hr
                  style="
                    height: 2px;
                    border-width: 0;'
                    color: gray;
                    background-color: gray;
                  "
                />
                <br />
                {{ this.campaignData.gold_ball_giveaway_date_str_3 }}
                <v-icon
                  dark
                  class="icons"
                  color="#6B6B6B"
                  size="22"
                  @click="editCampaign()"
                >
                  mdi-pencil
                </v-icon>
                <hr
                  style="
                    height: 2px;
                    border-width: 0;
                    color: gray;
                    background-color: gray;
                  "
                />
                <br />
                <div v-if="this.campaignData.gold_ball_player_list != null">
                  <tr class="students">
                    <strong>Players: </strong>
                    <td
                      v-for="(item, i) in goldballplayeritems"
                      :key="i"
                      v-text="item.name"
                      class="student show"
                    ></td>
                  </tr>
                </div>
               <strong>Category:</strong> Wizfit
                <br />
                <br />
                <strong>Location:</strong> {{ this.campaignData.organization_name }}
                  <br />  <br />
                  <strong>Address:</strong>{{ this.campaignData.shipping_address }}
                  <br />  <br />
                  <strong>Time:</strong> {{ this.campaignData.gold_ball_start_time }} - {{ this.campaignData.gold_ball_end_time }}
                  <br />  <br />
                  <strong>Student Enrollment:</strong>{{ this.campaignData.total_student}}/{{ this.campaignData.school_info_total_student }}
                  <br />  <br />
              <strong>Note: </strong>
                <div v-html=" this.campaignData.notes"></div> <br />
                <br />  <br />
                  <strong>Money Raised:</strong> {{ this.campaignData.original_total_fund_raised_50 }}
                  <br>
                  <strong>Financial Goal:</strong> {{ this.campaignData.total_goal_50 }}
                     <br />  <br />
                <strong>Description: </strong><div v-html=this.campaignData.special_message></div>
                <br />  <br />
                  <strong>School Goals And Incentives:</strong><br> 
                    Low Goal Condition:&nbsp;&nbsp;{{ this.campaignData.low_goal }}
                   <br>
                   Low Goal Purpose:&nbsp;&nbsp;{{ this.campaignData.low_goal_purpose }}
                    <br>
                   Middle Goal Condition:&nbsp;&nbsp;{{ this.campaignData.middle_goal}}
                    <br>
                   Middle Goal Purpose:&nbsp;&nbsp;{{ this.campaignData.middle_goal_purpose }}
                    <br>
                   Top Goal Condition:&nbsp;&nbsp;{{ this.campaignData.top_goal}}
                    <br>
                   Top Goal Purpose:&nbsp;&nbsp;{{ this.campaignData.top_goal_purpose }}
                   <br />  <br />
                <strong>Other Incentives:</strong>  <div v-html="this.campaignData.other_incentive"></div>
                   <br />  <br />
                      <strong style="font-size: 20px; text-decoration: underline"
                  >Contact Info:</strong
                >
                   <br />
                  <strong>Name:</strong>{{ this.campaignData.host_name }} 
                   <br />
                  <strong>Email:</strong> {{ this.campaignData.host_email }} 
                   <br />
                  <strong>Phone No.:</strong>
                <hr
                  style="
                    height: 2px;
                    border-width: 0;
                    color: gray;
                    background-color: gray;
                  "
                />
              </div>
              <div
                style="text-align: justify; font-size: 16px"
                v-if="this.chekinclude.includes('PR')"
              >
                <hr
                  style="
                    height: 2px;
                    border-width: 0;'
                    color: gray;
                    background-color: gray;
                  "
                />
                <br />
                {{ this.campaignData.pre_registration_date_str_3 }}
                <v-icon
                  dark
                  class="icons"
                  color="#6B6B6B"
                  size="22"
                  @click="editCampaign()"
                >
                  mdi-pencil
                </v-icon>
                <hr
                  style="
                    height: 2px;
                    border-width: 0;
                    color: gray;
                    background-color: gray;
                  "
                />
                <br />
                <div
                  v-if="this.campaignData.pre_registration_player_list != null"
                >
                  <tr class="students">
                    <strong>Players: </strong>
                    <td
                      v-for="(item, i) in preregistrationplayeritems"
                      :key="i"
                      v-text="item.name"
                      class="student show"
                    ></td>
                  </tr>
                </div>
                 <strong>Category:</strong> Wizfit
                <br />
                <br />
                <strong>Location:</strong> {{ this.campaignData.organization_name }}
                  <br />  <br />
                  <strong>Address:</strong> {{ this.campaignData.shipping_address }}
                  <br />  <br />
                  <strong>Time:</strong> {{ this.campaignData.pre_registration_start_time }} - {{ this.campaignData.pre_registration_end_time }}
                  <br />  <br />
                  <strong>Student Enrollment:</strong>{{ this.campaignData.total_student}}/{{ this.campaignData.school_info_total_student }}
                  <br />  <br />
                <strong>Note: </strong>
                <div v-html=" this.campaignData.notes"></div> <br />
                <br />  <br />
                  <strong>Money Raised:</strong> {{ this.campaignData.original_total_fund_raised_50 }}
                  <br>
                  <strong>Financial Goal:</strong> {{ this.campaignData.total_goal_50 }}
                     <br />  <br />
                <strong>Description: </strong><div v-html=this.campaignData.special_message></div>
                <br />  <br />
                  <strong>School Goals And Incentives:</strong><br> 
                    Low Goal Condition:&nbsp;&nbsp;{{ this.campaignData.low_goal }}
                   <br>
                   Low Goal Purpose:&nbsp;&nbsp;{{ this.campaignData.low_goal_purpose }}
                    <br>
                   Middle Goal Condition:&nbsp;&nbsp;{{ this.campaignData.middle_goal}}
                    <br>
                   Middle Goal Purpose:&nbsp;&nbsp;{{ this.campaignData.middle_goal_purpose }}
                    <br>
                   Top Goal Condition:&nbsp;&nbsp;{{ this.campaignData.top_goal}}
                    <br>
                   Top Goal Purpose:&nbsp;&nbsp;{{ this.campaignData.top_goal_purpose }}
                   <br />  <br />
                <strong>Other Incentives:</strong>  <div v-html="this.campaignData.other_incentive"></div>
                   <br />  <br />
                      <strong style="font-size: 20px; text-decoration: underline"
                  >Contact Info:</strong
                >
                   <br />
                  <strong>Name:</strong>{{ this.campaignData.host_name }} 
                   <br />
                  <strong>Email:</strong> {{ this.campaignData.host_email }} 
                   <br />
                  <strong>Phone No.:</strong> 
                <hr
                  style="
                    height: 2px;
                    border-width: 0;
                    color: gray;
                    background-color: gray;
                  "
                />
              </div>
              <div
                style="text-align: justify; font-size: 16px"
                v-if="this.chekinclude.includes('GR')"
              >
                <hr
                  style="
                    height: 2px;
                    border-width: 0;'
                    color: gray;
                    background-color: gray;
                  "
                />
                {{ this.campaignData.graduation_celebration_date_str_3 }}
                <v-icon
                  dark
                  class="icons"
                  color="#6B6B6B"
                  size="22"
                  @click="editCampaign()"
                >
                  mdi-pencil
                </v-icon>
                <hr
                  style="
                    height: 2px;
                    border-width: 0;
                    color: gray;
                    background-color: gray;
                  "
                />
                <br />
                <div v-if="this.campaignData.graduation_player_list != null">
                  <tr class="students">
                    <strong>Players: </strong>
                    <td
                      v-for="(item, i) in graduationplayeritems"
                      :key="i"
                      v-text="item.name"
                      class="student show"
                    ></td>
                  </tr>
                </div>
              <strong>Category:</strong> Wizfit
                <br />
                <br />
                <strong>Location:</strong> {{ this.campaignData.organization_name }}
                  <br />  <br />
                  <strong>Address:</strong> {{ this.campaignData.shipping_address }}
                  <br />  <br />
                  <strong>Time:</strong> {{ this.campaignData.graduation_start_time }} - {{ this.campaignData.graduation_end_time }}
                  <br />  <br />
                  <strong>Student Enrollment:</strong>{{ this.campaignData.total_student}}/{{ this.campaignData.school_info_total_student }}
                  <br />  <br />
              <strong>Note: </strong>
                <div v-html=" this.campaignData.notes"></div> <br />
                <br />  <br />
                  <strong>Money Raised:</strong> {{ this.campaignData.original_total_fund_raised_50 }}
                  <br>
                  <strong>Financial Goal:</strong> {{ this.campaignData.total_goal_50 }}
                     <br />  <br />
                <strong>Description: </strong><div v-html=this.campaignData.special_message></div>
                <br />  <br />
                  <strong>School Goals And Incentives:</strong><br> 
                    Low Goal Condition:&nbsp;&nbsp;{{ this.campaignData.low_goal }}
                   <br>
                   Low Goal Purpose:&nbsp;&nbsp;{{ this.campaignData.low_goal_purpose }}
                    <br>
                   Middle Goal Condition:&nbsp;&nbsp;{{ this.campaignData.middle_goal}}
                    <br>
                   Middle Goal Purpose:&nbsp;&nbsp;{{ this.campaignData.middle_goal_purpose }}
                    <br>
                   Top Goal Condition:&nbsp;&nbsp;{{ this.campaignData.top_goal}}
                    <br>
                   Top Goal Purpose:&nbsp;&nbsp;{{ this.campaignData.top_goal_purpose }}
                   <br />  <br />
                 <strong>Other Incentives:</strong>  <div v-html="this.campaignData.other_incentive"></div>
                   <br />  <br />
                      <strong style="font-size: 20px; text-decoration: underline"
                  >Contact Info:</strong
                >
                   <br />
                  <strong>Name:</strong>{{ this.campaignData.host_name }} 
                   <br />
                  <strong>Email:</strong> {{ this.campaignData.host_email }} 
                   <br />
                  <strong>Phone No.:</strong>
                <hr
                  style="
                    height: 2px;
                    border-width: 0;
                    color: gray;
                    background-color: gray;
                  "
                />
              </div>
                <div
                  style="text-align: justify; font-size: 16px"
                  v-if="this.chekinclude.includes('Eblast goldball date')"
                >
                  <hr
                    style="
                    height: 2px;
                    border-width: 0;'
                    color: gray;
                    background-color: gray;
                  "
                  />
                  {{ this.campaignData.gold_ball_eblast_date_str_3 }}
                  <v-icon
                    dark
                    class="icons"
                    color="#6B6B6B"
                    size="22"
                    @click="editCampaign()"
                  >
                    mdi-pencil
                  </v-icon>
                  <hr
                    style="
                    height: 2px;
                    border-width: 0;'
                    color: gray;
                    background-color: gray;
                  "
                  />
                   <strong>Category:</strong> Wizfit
                <br />
                <br />
                <strong>Location:</strong> {{ this.campaignData.organization_name }}
                  <br />  <br />
                  <strong>Address:</strong> {{ this.campaignData.shipping_address }}
                  <br />  <br />
                  <strong>Student Enrollment:</strong>{{ this.campaignData.total_student}}/{{ this.campaignData.school_info_total_student }}
                  <br />  <br />
               <strong>Note: </strong>
                <div v-html=" this.campaignData.notes"></div> <br />
                <br />  <br />
                  <strong>Money Raised:</strong> {{ this.campaignData.original_total_fund_raised_50 }}
                  <br>
                  <strong>Financial Goal:</strong> {{ this.campaignData.total_goal_50 }}
                     <br />  <br />
                <strong>Description: </strong><div v-html=this.campaignData.special_message></div>
                <br />  <br />
                  <strong>School Goals And Incentives:</strong><br> 
                    Low Goal Condition:&nbsp;&nbsp;{{ this.campaignData.low_goal }}
                   <br>
                   Low Goal Purpose:&nbsp;&nbsp;{{ this.campaignData.low_goal_purpose }}
                    <br>
                   Middle Goal Condition:&nbsp;&nbsp;{{ this.campaignData.middle_goal}}
                    <br>
                   Middle Goal Purpose:&nbsp;&nbsp;{{ this.campaignData.middle_goal_purpose }}
                    <br>
                   Top Goal Condition:&nbsp;&nbsp;{{ this.campaignData.top_goal}}
                    <br>
                   Top Goal Purpose:&nbsp;&nbsp;{{ this.campaignData.top_goal_purpose }}
                   <br />  <br />
                <strong>Other Incentives:</strong>  <div v-html="this.campaignData.other_incentive"></div>
                   <br />  <br />
                      <strong style="font-size: 20px; text-decoration: underline"
                  >Contact Info:</strong
                >
                   <br />
                  <strong>Name:</strong>{{ this.campaignData.host_name }} 
                   <br />
                  <strong>Email:</strong> {{ this.campaignData.host_email }} 
                   <br />
                  <strong>Phone No.:</strong>
                <hr
                  style="
                    height: 2px;
                    border-width: 0;
                    color: gray;
                    background-color: gray;
                  "
                />
                </div>
                <div
                  style="text-align: justify; font-size: 16px"
                  v-if="this.chekinclude.includes('Eblast graduation date')"
                >
                  <hr
                    style="
                    height: 2px;
                    border-width: 0;'
                    color: gray;
                    background-color: gray;
                  "
                  />
                  {{ this.campaignData.graduation_eblast_date_str_3 }}
                  <v-icon
                    dark
                    class="icons"
                    color="#6B6B6B"
                    size="22"
                    @click="editCampaign()"
                  >
                    mdi-pencil
                  </v-icon>
                  <hr
                    style="
                    height: 2px;
                    border-width: 0;'
                    color: gray;
                    background-color: gray;
                  "
                  />
                   <strong>Category:</strong> Wizfit
                <br />
                <br />
                <strong>Location:</strong> {{ this.campaignData.organization_name }}
                  <br />  <br />
                  <strong>Address:</strong>{{ this.campaignData.shipping_address }}
                  <br />  <br />
                  <strong>Student Enrollment:</strong> {{ this.campaignData.total_student}}/{{ this.campaignData.school_info_total_student }}
                  <br />  <br />
               <strong>Note: </strong>
                <div v-html=" this.campaignData.notes"></div> <br />
                <br />  <br />
                  <strong>Money Raised:</strong> {{ this.campaignData.original_total_fund_raised_50 }}
                  <br>
                  <strong>Financial Goal:</strong> {{ this.campaignData.total_goal_50 }}
                     <br />  <br />
                <strong>Description: </strong><div v-html=this.campaignData.special_message></div>
                <br />  <br />
                  <strong>School Goals And Incentives:</strong><br> 
                    Low Goal Condition:&nbsp;&nbsp;{{ this.campaignData.low_goal }}
                   <br>
                   Low Goal Purpose:&nbsp;&nbsp;{{ this.campaignData.low_goal_purpose }}
                    <br>
                   Middle Goal Condition:&nbsp;&nbsp;{{ this.campaignData.middle_goal}}
                    <br>
                   Middle Goal Purpose:&nbsp;&nbsp;{{ this.campaignData.middle_goal_purpose }}
                    <br>
                   Top Goal Condition:&nbsp;&nbsp;{{ this.campaignData.top_goal}}
                    <br>
                   Top Goal Purpose:&nbsp;&nbsp;{{ this.campaignData.top_goal_purpose }}
                   <br />  <br />
                <strong>Other Incentives:</strong>  <div v-html="this.campaignData.other_incentive"></div>
                   <br />  <br />
                      <strong style="font-size: 20px; text-decoration: underline"
                  >Contact Info:</strong
                >
                   <br />
                  <strong>Name:</strong>{{ this.campaignData.host_name }} 
                   <br />
                  <strong>Email:</strong> {{ this.campaignData.host_email }} 
                   <br />
                  <strong>Phone No.:</strong> 
                <hr
                  style="
                    height: 2px;
                    border-width: 0;
                    color: gray;
                    background-color: gray;
                  "
                />
                </div>
                <div
                  style="text-align: justify; font-size: 16px"
                  v-if="this.chekinclude.includes('Event End Date')"
                >
                  <hr
                    style="
                    height: 2px;
                    border-width: 0;'
                    color: gray;
                    background-color: gray;
                  "
                  />
                  {{ this.campaignData.end_date_str_3 }}
                  <v-icon
                    dark
                    class="icons"
                    color="#6B6B6B"
                    size="22"
                    @click="editCampaign()"
                  >
                    mdi-pencil
                  </v-icon>
                  <hr
                    style="
                    height: 2px;
                    border-width: 0;'
                    color: gray;
                    background-color: gray;
                  "
                  />
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-menu>
          <img
          src="@/assets/thingsToDo/loader5.gif"
          width="100"
          height="100"
          style="margin-left: 740px"
          v-if="loading"
        />
        <v-calendar
          ref="calendar"
          color="primary"
          :events="events"
          v-model="value"
          type="month"
          :weekdays="weekday"
          :event-overlap-mode="mode"
          :event-overlap-threshold="30"
          @click:event="showEvent"
          @change="updateRange"
          style="height: auto"
        ></v-calendar>
       <div>
        <ul>
          <li style="color: #8c6ee3">Wizfit</li>
          <li style="color: #a9a9a9">Unassigned</li>
        </ul>
      </div>
      </div>
    </div>
    <add-task-modal></add-task-modal>
  </v-app>
</template>
<script>
import {
  CAMPAIGN_CALENDAR_API,
  API_ADMIN_CAMPAIGN_MANAGEMENT_GET,
} from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { ROUTER_URL } from "../../../constants/urls";
export default {
  name: "App",
  components: {
    AddTaskModal: () =>
      import("../AddTaskCampaignModal/AddTaskCampaignModal.vue"),
  },
  data: () => {
    return {
      focus: "",
      events: [],
      mode: "stack",
      modes: ["stack", "column"],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
        { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
        { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },

        { text: "Mon, Wed, Fri", value: [1, 3, 5] },
      ],
      value: "",
      colors: ["blue", "black", "orange"],
      today: new Date(),
      firstcalldate: "",
      selectedOpen: false,
      selectedElement: undefined,
      selectedEvent: {},
      eventcategoryid: localStorage.getItem("eventid"),
      startdatefmin: localStorage.getItem("startdatefilter"),
      gameData: {},
      gamedisplaydate: "",
      assemblydisplaydatess: "",
      assemblyData: {},
      hotelData: {},
      hoteldisplaydatess: "",
      ptoDetail: {},
      campaignData: {},
      schoolCampaignData:{},
      loading: false,
    };
  },
  mounted() {
    // this.getMonthCalendarData();
    this.$refs.calendar.scrollToTime("07:00");
  },
  created() {
    this.$root.$refs.campaigncalendar = this;
  },
  methods: {
    ...mapActions({
      toggleAddTaskModal: "campaignManagement/toggleTaskModal",
      showToast: "snackBar/showToast",
      // calendar Modal
    }),

    getEventColor(event) {
      return event.color;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    updateRange({ start, end }) {
      const min = new Date(`${start.date}T24:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      this.firstdatemin = moment(min).format("YYYY-MM-DD");
      this.lastdatemin = moment(max).format("YYYY-MM-DD");
      this.startMonth = this.$refs.calendar.title;
      this.getMonthCalendarData();
    },
    getMonthCalendarData() {
      this.events = [];
      const self = this;
      self.loading = true;
      console.log("in mounted");
      const successHandler = (res) => {
        this.eventss = res.data.calendar_list;
        for (const items of this.eventss) {
          if (
            items.event_type == "kick_off_date" &&
            items.campaigns[0].kick_off_team_name != null &&
            items.campaigns[0].kick_off_player_list == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +","+items.state+
                "  - KO (" +
                items.campaigns[0].kick_off_presence +
                "-" +
                items.campaigns[0].kick_off_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - KO (" +
                items.campaigns[0].kick_off_presence +
                "-" +
                items.campaigns[0].kick_off_team_name +
                ")";

            const start = items.start_date;
            const color = "#8c6ee39e";
            const eventids = "2";
            const id = items.campaign_id;
            this.events.push({ name, start, color, eventids,id });
          }
          if (
            items.event_type == "kick_off_date" &&
            items.campaigns[0].kick_off_player_list != null &&
            items.campaigns[0].kick_off_team_name == null
          ) {
            const name = items.school_name
              ? " WF -" +
                 items.city +","+items.state+
                "  - KO (" +
                items.campaigns[0].kick_off_presence +
                "-" +
                items.campaigns[0].kick_off_player_list.map((player) => player.name) +
                ")"
              : " WF -" +
                items.district_name +
                "  - KO (" +
                items.campaigns[0].kick_off_presence +
                "-" +
                items.campaigns[0].kick_off_player_list.map((player) => player.name)+
                ")";

            const start = items.start_date;
            const color = "#8c6ee39e";
            const eventids = "2";
            const id = items.campaign_id;
            this.events.push({ name, start, color,eventids, id });
          }
          if (
            items.event_type == "kick_off_date" &&
            items.campaigns[0].kick_off_player_list == null &&
            items.campaigns[0].kick_off_team_name == null
          ) {
            const name = items.school_name
              ? " WF -" +
                 items.city +","+items.state+
                "  - KO (" +
                items.campaigns[0].kick_off_presence +
                "-Unassigned)"
              : " WF -" +
                items.district_name +
                "  - KO (" +
                items.campaigns[0].kick_off_presence +
                "-Unassigned)";

            const start = items.start_date;
            const color = "#A9A9A9";
             const eventids = "2";
            const id = items.campaign_id;
            this.events.push({ name, start, color,eventids, id });
          }
          if (
            items.event_type == "gold_ball_giveaway_date" &&
            items.campaigns[0].gold_ball_team_name!= null &&
            items.campaigns[0].gold_ball_player_list == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +","+items.state+
                "  - GB (" +
                items.campaigns[0].gold_ball_presence +
                "-" +
                items.campaigns[0].gold_ball_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - GB (" +
                items.campaigns[0].gold_ball_presence +
                "-" +
                items.campaigns[0].gold_ball_team_name +
                ")";

            const start = items.start_date;
            const color = "#8c6ee39e";
             const eventids = "2";
            const id = items.campaign_id;
            this.events.push({ name, start, color,eventids, id });
          }
          if (
            items.event_type == "gold_ball_giveaway_date" &&
            items.campaigns[0].gold_ball_player_list != null &&
            items.campaigns[0].gold_ball_team_name== null
          ) {
            const name = items.school_name
              ? " WF -" +
                  items.city +","+items.state+
                "  - GB (" +
                items.campaigns[0].gold_ball_presence +
                "-" +
                items.campaigns[0].gold_ball_player_list.map((player) => player.name) +
                ")"
              : " WF -" +
                items.district_name +
                "  - GB (" +
                items.campaigns[0].gold_ball_presence +
                "-" +
                items.campaigns[0].gold_ball_player_list.map((player) => player.name) +
                ")";

            const start = items.start_date;
            const color = "#8c6ee39e";
             const eventids = "2";
            const id = items.campaign_id;
            this.events.push({ name, start, color,eventids, id });
          }
          if (
            items.event_type == "gold_ball_giveaway_date" &&
            items.campaigns[0].gold_ball_player_list == null &&
            items.campaigns[0].gold_ball_team_name== null
          ) {
            const name = items.school_name
              ? " WF -" +
                 items.city +","+items.state+
                "  - GB (" +
                items.campaigns[0].gold_ball_presence +
                "- Unassigned)"
              : " WF -" +
                items.district_name +
                "  - GB (" +
                items.campaigns[0].gold_ball_presence +
                "- Unassigned)";

            const start = items.start_date;
            const color = "#A9A9A9";
             const eventids = "2";
            const id = items.campaign_id;
            this.events.push({ name, start, color,eventids, id });
          }
          if (
            items.event_type == "graduation_celebration_date" &&
            items.campaigns[0].graduation_team_name!= null &&
            items.campaigns[0].graduation_player_list == null
          ) {
            const name = items.school_name
              ? " WF -" +
                   items.city +","+items.state+
                "  - GR (" +
                items.campaigns[0].graduation_presence +
                "-" +
                items.campaigns[0].graduation_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - GR (" +
                items.campaigns[0].graduation_presence +
                "-" +
                items.campaigns[0].graduation_team_name +
                ")";

            const start = items.start_date;
            const color = "#8c6ee39e";
             const eventids = "2";
            const id = items.campaign_id;
            this.events.push({ name, start, color,eventids, id });
          }
          if (
            items.event_type == "graduation_celebration_date" &&
            items.campaigns[0].graduation_player_list != null &&
            items.campaigns[0].graduation_team_name== null
          ) {
            const name = items.school_name
              ? " WF -" +
               items.city +","+items.state+
                "  - GR (" +
                items.campaigns[0].graduation_presence +
                "-" +
                items.campaigns[0].graduation_player_list.map((player) => player.name)+
                ")"
              : " WF -" +
                items.district_name +
                "  - GR (" +
                items.campaigns[0].graduation_presence +
                "-" +
                items.campaigns[0].graduation_player_list.map((player) => player.name)+
                ")";

            const start = items.start_date;
            const color = "#8c6ee39e";
             const eventids = "2";
            const id = items.campaign_id;
            this.events.push({ name, start, color,eventids, id });
          }
          if (
            items.event_type == "graduation_celebration_date" &&
            items.campaigns[0].graduation_player_list == null &&
            items.campaigns[0].graduation_team_name== null
          ) {
            const name = items.school_name
              ? " WF -" +
              items.city +","+items.state+
                "  - GR (" +
                items.campaigns[0].graduation_presence +
                "-Unassigned)"
              : " WF -" +
                items.district_name +
                "  - GR (" +
                items.campaigns[0].graduation_presence +
                "-Unassigned)";

            const start = items.start_date;
            const color = "#A9A9A9";
             const eventids = "2";
            const id = items.campaign_id;
            this.events.push({ name, start, color,eventids, id });
          }
          if (
            items.event_type == "pre_registration_date" &&
            items.campaigns[0].pre_registration_team != null &&
            items.campaigns[0].pre_registration_player_list == null
          ) {
          if(  items.campaigns[0].pre_registration_date_str != null ){
            const name = items.school_name
              ? " WF -" +
                  items.city +","+items.state+
                "  - PR (" +
                items.campaigns[0].pre_registration_presence +
                "-" +
                items.campaigns[0].pre_registration_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - PR (" +
                items.campaigns[0].pre_registration_presence +
                "-" +
                items.campaigns[0].pre_registration_team_name +
                ")";

            const start = items.start_date;
            const color = "#8c6ee39e";
             const eventids = "2";
            const id = items.campaign_id;
            this.events.push({ name, start, color,eventids ,id });
          }
          }
          if (
            items.event_type == "pre_registration_date" &&
            items.campaigns[0].pre_registration_player_list != null &&
            items.campaigns[0].pre_registration_team == null
          ) {
           if(  items.campaigns[0].pre_registration_date_str != null ){
            const name = items.school_name
              ? " WF -" +
                  items.city +","+items.state+
                "  - PR (" +
                items.campaigns[0].pre_registration_presence +
                "-" +
                items.campaigns[0].pre_registration_player_list.map((player) => player.name)+
                ")"
              : " WF -" +
                items.district_name +
                "  - PR (" +
                items.campaigns[0].pre_registration_presence +
                "-" +
                items.campaigns[0].pre_registration_player_list.map((player) => player.name) +
                ")";

            const start = items.start_date;
            const color = "#8c6ee39e";
             const eventids = "2";
            const id = items.campaign_id;
            this.events.push({ name, start, color, eventids,id });
          }
          }
          if (
            items.event_type == "pre_registration_date" &&
            items.campaigns[0].pre_registration_player_list == null &&
            items.campaigns[0].pre_registration_team == null
          ) {
           if(  items.campaigns[0].pre_registration_date_str != null ){
            const name = items.school_name
              ? " WF -" +
                  items.city +","+items.state+
                "  - PR (" +
                items.campaigns[0].pre_registration_presence +
                "-Unassigned)"
              : " WF -" +
                items.district_name +
                "  - PR (" +
                items.campaigns[0].pre_registration_presence +
                "-Unassigned)";
             
            const start = items.start_date;
            const color = "#A9A9A9";
             const eventids = "2";
            const id = items.campaign_id;
            this.events.push({ name, start, color,eventids, id });
          }
          }
          if (items.event_type == "gold_ball_eblast_date") {
            const name = "Eblast goldball date";

            const start = items.start_date;
            const color = "#8c6ee39e";
            const id = items.campaign_id;
            const eventids = "2";
            this.events.push({ name, start, color, id, eventids });
          }
          if (items.event_type == "graduation_eblast_date") {
            const name = "Eblast graduation date";

            const start = items.start_date;
            const color = "#8c6ee39e";
            const id = items.campaign_id;
            const eventids = "2";
            this.events.push({ name, start, color, id, eventids });
          }
          if (items.event_type == "campaign") {
            const name = "Event End Date";

            const start = items.end_date;
            const color = "#8c6ee39e";
            const id = items.campaign_id;
            const eventids = "2";
            this.events.push({ name, start, color, id, eventids });
          }
          if (items.event_category == 5) {
            const name = items.task_title;
            const start = items.start_date;
            const end = items.end_date;
            const color = "#F9B4F6";
            const id = items.id;
            const eventids = "5";
            this.events.push({ name, start, end, color, id, eventids });
          }
        }
        self.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res.data);
        self.loading = false;
      };
      let formData = {};
      formData["campaign_id"] = this.selectedCampaign.campaignData.id;
      Axios.request_GET(
        CAMPAIGN_CALENDAR_API,

        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true
      );
    },
    getCampaignDetail() {
      const successHandler = (res) => {
        console.log(res.data);
        this.campaignData = res.data.campaign_detail;
        var wizfitdatess = new Date(this.campaignData.start_date);
        var datewizfit = new Date(wizfitdatess);
        this.wizfitdisplaydate = datewizfit.toDateString();
        if (this.campaignData.kick_off_player_list != null) {
          this.kickoffplayeritems = this.campaignData.kick_off_player_list;
        }
        if (this.campaignData.gold_ball_player_list != null) {
          this.goldballplayeritems = this.campaignData.gold_ball_player_list;
        }
        if (this.campaignData.graduation_player_list != null) {
          this.graduationplayeritems = this.campaignData.graduation_player_list;
        }
        if (this.campaignData.pre_registration_player_list != null) {
          this.preregistrationplayeritems =
            this.campaignData.pre_registration_player_list;
        }
        
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["campaign_id"] = this.selectedEvent.id;
      Axios.request_GET(
        API_ADMIN_CAMPAIGN_MANAGEMENT_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getptoDetail() {
      const successHandler = (res) => {
        console.log(res.data);
        this.ptoDetail = res.data.calendar_detail;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      Axios.request_GET(
        "/calendar/" + this.selectedEvent.id,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
      deleteTaskData() {
      console.log("delete");
      const successHandler = (res) => {
        console.log(res);
        this.showToast({
          message: "Deleted Successfully",
          color: "s",
        });
        this.getMonthCalendarData();
      };
      const failureHandler = (res) => {
        console.log(res);
        this.showToast({
          message: res,
          color: "e",
        });
      };
      const finallyHandler = () => {};
      let formData = {};
      Axios.request_DELETE(
        "/calendar/" + this.selectedEvent.id + "/",
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    dateChange(date) {
      console.log(date);
    },
    setToday() {
      this.focus = "";
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.chekinclude = this.selectedEvent.name;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
      if (this.selectedEvent.eventids == 5) {
        this.getptoDetail();
      }
      if (this.selectedEvent.eventids == 2) {
        this.getCampaignDetail();
      }
    },
    editCampaign() {
      let routerData = this.$router.resolve({
        // name: ROUTER_URL.adminPanel.children.newCampaign.name,
        name: ROUTER_URL.adminPanel.children.newCampaign.name,
        query: { type: "edit", id: this.selectedEvent.id },
      });
      window.open(routerData.href, "_blank");
    },
  },
  computed: {
    ...mapGetters({
      selectedCampaign: "campaign/getSelectedCampaign",
    }),
    selectedEventStart() {
      return this.selectedEvent && this.selectedEvent.start;
    },
    selectedEventEnd() {
      return this.selectedEvent && this.selectedEvent.end;
    },
  },
};
</script>
