<template>
  <div class="manage-account">
    <header>
      <img
        src="https://harlemwizards.com/wp/wp-content/uploads/2024/04/New-Logo.web_.png"
        alt="Site Logo"
      />
      <span class="logo-text">Harlem Wizards</span>
    </header>

    <div class="content">
      <h1>How can I manage or delete account and data about me?</h1>
      <p style="color: black">
        Users can manage their content and information. Additionally, accounts
        can be deleted at any time.
      </p>
      <p style="color: black">
        To request the deletion of personal data with contact information,
        please follow these steps:
      </p>

      <ol>
        <li><strong>Open the App:</strong> Launch the app on your device.</li>
        <li><strong>Open Drawer:</strong> Click on the top left menu icon.</li>
        <li>
          <strong>Select “Delete My Account and Data”:</strong> Find and select
          the option labeled “Delete My Account and Data.”
        </li>
        <li>
          <strong>Review the Information:</strong> An alert dialog will appear.
          Review the information.
        </li>
        <li>
          <strong>Click on Submit:</strong> Once you have reviewed the
          information, click on “Submit” to complete the deletion process.
        </li>
      </ol>

      <p>
        After following these steps, your data will be completely deleted from
        the app within 30 days. When you delete your account, all of your data
        will be deleted.
      </p>
    </div>

    <footer>
      <p>Copyright © 2025 • Harlem Wizards</p>
    </footer>
  </div>
</template>

<script>
export default {
  name: "ManageAccountPage",
};
</script>

<style scoped>
/* Global Styles */
body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f8f8f8;
  box-sizing: border-box;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

header {
  background-color: #150942;
  padding: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

header img {
  max-height: 60px;
  width: auto;
  margin-right: 10px;
}

.logo-text {
  font-size: 24px;
  font-weight: 600;
  color: #fff;
}

/* Main content area */
.content {
  width: 90%;
  max-width: 900px;
  margin: 40px auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  flex-grow: 1;
}

h1 {
  font-size: 28px;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
}

p {
  font-size: 16px;
  color: #ffffff;
  line-height: 1.8;
  margin-bottom: 20px;
}

ol {
  font-size: 16px;
  color: #333;
  line-height: 1.8;
  padding-left: 20px;
}

ol li {
  margin-bottom: 10px;
}

footer {
  background-color: #150942;
  color: #fff;
  padding: 15px;
  text-align: center;
  margin-top: 14%;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  header {
    padding: 15px;
  }

  .content {
    width: 95%;
    padding: 15px;
  }

  h1 {
    font-size: 24px;
  }

  p,
  ol {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  header {
    padding: 10px;
  }

  header img {
    max-height: 50px;
  }

  .content {
    width: 95%;
    padding: 10px;
  }

  h1 {
    font-size: 22px;
  }

  p,
  ol {
    font-size: 13px;
  }
}
</style>
