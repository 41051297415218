<template>
  <div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="600px"
      persistent
    >
      <div class="text-center" v-if="!loading" style="min-height: 600px">
        <v-progress-circular
          :size="100"
          :width="10"
          style="
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>

      <v-card background-color="#f2f2f2" style="height: 450px" v-if="loading">
        <v-card-title
          class="py-0 pt-5"
          style="background-color: #7253cf; color: white"
        >
          <div class="d-flex px-1">
            <img
              src="@/assets/basketLogoDialogDistrictManagement.svg"
              height="85"
              width="100"
            />
          </div>
          <div class="dialogTitle-text" style="font-size: 28px">
            Wizfit Challenge
          </div>
          <v-spacer></v-spacer>
          <div style="position: relative">
            <v-icon
              text
              class="close-btn"
              color="white"
              @click="toggleModal({ Delete: false })"
            >
              mdi-close
            </v-icon>
          </div>
        </v-card-title>
        <v-card-text class="pb-3 text-center" style="padding: 20px">
          <h3 style="font-size: 20px; font-weight: bold; margin-bottom: 15px">
            Welcome back to the WizFit Challenge – we’re so excited to have you
            join us again! 🎉
          </h3>
          <p style="font-size: 17px; line-height: 1.6; margin-bottom: 10px">
            For security reasons, we don’t store user data from previous years.
            But no worries, you can quickly re-register using our sign-up form.
          </p>
          <p style="font-size: 17px; line-height: 1.6">
            Thank you for your amazing support – it means the world to us. Let’s
            make this year the best one yet. Let’s get WizFit!
          </p>
        </v-card-text>
        <v-container class="d-flex justify-center">
    <v-btn 
      @click="registerStudentHandler()" 
      color="#CC0000" 
      class="custom-btn"
    >
      Register
    </v-btn>
  </v-container>
      </v-card>
     
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "RegisterMessageModal",
  components: {},
  data() {
    return {
      loading: true,
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      showGetter: "publicDonationPage/getRegisterMessageModalShow",
    }),
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.toggleRegisterMessageModal({ show: value });
      },
    },
  },
  watch: { getterSignUpPageFlag(value) {
      if (value) {
        this.signUpPageFlag = this.getterSignUpPageFlag;
        if (this.signUpPageFlag) {
        this.modalActionText.title = "Parent Sign up";
        this.modalActionText.actionSub = "Already have an account? ";
        this.modalActionText.actionBtn = "Login";
      } else {
        this.modalActionText.title = "Welcome back, Parent";
        this.modalActionText.actionSub = "Dont have an account? ";
        this.modalActionText.actionBtn = "Signup";
      }
      }
    },},
  methods: {
    ...mapActions({
      toggleModal: "publicDonationPage/toggleRegisterMessageModal",
     toggleParentSignUpModal: "publicDonationPage/toggleParentSignUpModal",
      showToast: "snackBar/showToast",
    }),
   registerStudentHandler() {
  this.toggleModal({ show: false }); // Close the current popup
  this.toggleParentSignUpModal({ show: true, signUpPageFlag: true }); // Open the parent sign-up modal
},
  },
  mounted() {
    this.loading = true;
  },
};
</script>

<style scoped>
.close-btn {
  position: absolute; /* Makes it float within the parent container */
  top: -53px; /* Adjusts distance from the top */
  right: 10px; /* Adjusts distance from the right */
  color: white; /* Sets the button text/icon color */
  border-radius: 50%; /* Makes the button circular */
  width: 40px; /* Sets width */
  height: 40px; /* Sets height */
  display: flex; /* Centers the content inside */
  cursor: pointer; /* Changes cursor on hover */
  font-size: 25px; /* Adjusts icon size */
}
.closebtnclass {
  cursor: pointer;
  position: relative;
  right: -15px;
  bottom: 40px;
}
.alignCenter-text {
  text-align: -webkit-center;
  text-align: -moz-center;
}
.lowerBody-secondryTextFirst {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 145%;
  text-align: center;
  color: #2d2d2d;
}

.lowerBody-doLaterBtn span {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 145%;
  text-decoration-line: underline;
  color: #cc0000;
  letter-spacing: 0px;
}
.v-input--checkbox::v-deep {
  .v-label,
  .v-icon {
    color: black;
    font-size: 20px;
  }
}
/* Styles for large screens (web view, 1281px and wider) */
@media (min-width: 1281px) {
  .upperBody-text {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 145%;
    color: #2d2d2d;
    align-self: center;
    margin-left: 36px;
  }
  .pledgeClass {
    font-size: 20px;
    margin-left: 10px; /* Adjust margin for better spacing */
  }
  .lowerBody-secondryTextSecond {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 145%;
    text-align: center;
    color: #2d2d2d;
  }
  .imgwidthclass {
    width: 150px;
  }
  .copyUrlBtn span {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;
    text-align: center;
    color: #2c1963;
    letter-spacing: 0px;
    text-transform: capitalize;
  }
}

/* Styles for smaller screens (mobile view, 1280px and smaller) */
@media (max-width: 1280px) {
  .upperBody-text {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 15px; /* Reduced font size for smaller screens */
    line-height: 145%;
    color: #2d2d2d;
    align-self: center;
    margin-left: 6px;
  }

  .pledgeClass {
    font-size: 13px;
    margin-left: 10px; /* Adjust margin for better spacing */
  }
  .lowerBody-secondryTextSecond {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 145%;
    text-align: center;
    color: #2d2d2d;
  }
  .imgwidthclass {
    width: 100px;
  }
  .copyUrlBtn span {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;
    text-align: center;
    color: #2c1963;
    letter-spacing: 0px;
    text-transform: capitalize;
  }
}
@media (max-width: 600px) {
  .alignCenter-text v-sheet {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center;
  }

  .copyUrlBtn {
    margin-top: 50px; /* Add space below the share icons */
    margin-left: -150px;
  }
  .upperBody-text {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 15px; /* Reduced font size for smaller screens */
    line-height: 145%;
    color: #2d2d2d;
    align-self: center;
    margin-left: 6px;
  }
  .copyUrlBtn span {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;
    text-align: center;
    color: #2c1963;
    letter-spacing: 0px;
    text-transform: capitalize;
  }
  .closebtnclass {
    font-size: 27px;
    cursor: pointer;
    position: relative;
    bottom: 94px;
    left: 300px;
  }
}
@media only screen and (max-width: 768px) {
  .close-btn {
    top: -53px; /* Adjust for smaller devices */
    right: 5px;
    width: 35px; /* Slightly smaller size for mobile */
    height: 35px;
    font-size: 25px; /* Adjust icon size */
  }
}

@media only screen and (max-width: 480px) {
  .close-btn {
    top: -53px; /* Even closer to the edges for very small devices */
    right: 3px;
    width: 30px;
    height: 30px;
    font-size: 25px; /* Smallest size for tiny screens */
  }
}
.custom-btn {
  color: white !important; /* Ensures text remains white */
  font-weight: bold;
  text-transform: uppercase; /* Optional: Makes text uppercase */
  font-size: 16px; /* Adjust the size as needed */
}
</style>
