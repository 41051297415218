<template>
  <div class="px-7 py-7">
    <template>
      <v-row>
        <v-col cols="12" md="2" class="pl-3">
          <v-autocomplete
            label="Filter by Year"
            outlined
            dense
            v-model="yearFilter"
            :items="yearList"
            class="formFields"
            color="#7253CF"
            @change="getGameData()"
            :disabled="loading"
            v-if="
              this.adminAccess === 'Super Admin' ||
              this.adminAccess == 'Sales Representative' ||
              this.adminAccess == 'Event Support Staff'
            "
          >
          </v-autocomplete>
        </v-col>
        <v-col
          class="d-flex justify-end text-right"
          cols="12"
          md="3"
          v-if="
            this.adminAccess === 'Super Admin' ||
            this.adminAccess == 'Sales Representative' ||
            this.adminAccess == 'Event Support Staff'
          "
        >
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            max-width="285px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :disabled="loading"
                v-model="dateRangeText1"
                label="Filter by Created"
                prepend-inner-icon="mdi-calendar-blank"
                clearable
                v-bind="attrs"
                outlined
                v-on="on"
                dense
                height="43px"
                color="#2C1963"
                style="font-size: 15px; margin: 5px"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dates1"
              range
              no-title
              @input="filterDate1(dates1)"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          class="d-flex justify-end text-right"
          cols="12"
          md="3"
          v-if="
            this.adminAccess === 'Super Admin' ||
            this.adminAccess == 'Sales Representative' ||
            this.adminAccess == 'Event Support Staff'
          "
        >
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            max-width="285px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :disabled="loading"
                v-model="dateRangeText"
                label="Filter by Date"
                prepend-inner-icon="mdi-calendar-blank"
                clearable
                v-bind="attrs"
                outlined
                v-on="on"
                dense
                height="43px"
                color="#2C1963"
                style="font-size: 15px; margin: 5px"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              range
              no-title
              @input="filterDate(dates)"
            >
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" md="2" class="pl-3" style="margin-top: -17px">
          <h3 style="font-family: system-ui" v-if="this.gameCount">
            Total Games - {{ this.gameCount }}
          </h3>
        </v-col>
  <v-col cols="12" md="2" class="pl-3" style="margin-top: -17px">
          <v-btn
            color="#38227A"
            dark
            class="px-11 text-capitalize"
            style="border-radius: 10px; margin: 5px; width: 110px"
            height="45px"
            @click="getReport()"
            >Download<br>Game Detail</v-btn
          >
           <v-btn
            color="#38227A"
            dark
            class="px-11 text-capitalize"
            style="border-radius: 10px; margin: 5px; width: 95px"
            height="45px"
            @click="route()"
            >Upload<br>Game Detail</v-btn
          >
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="3">
          <v-text-field
            hide-details=""
            outlined
            height="43px"
            dense
            v-model="search"
            label="Search by Organization Name, City, Sales Rep, Event Support"
            append-icon="mdi-magnify"
            type="text"
            color="#38227A"
            class="search-input"
            style="margin: 5px"
            v-if="
              this.adminAccess === 'Super Admin' ||
              this.adminAccess == 'Sales Representative' ||
              this.adminAccess == 'Event Support Staff'
            "
           @input="onSearchInput"
           :disabled="loading">
          </v-text-field>
        </v-col>
        <v-col cols="12" md="2" class="pl-3" style="margin-top: 7px">
          <v-autocomplete
            label="Filter by Presale Status"
            outlined
            dense
            v-model="presale_filter"
            :items="presaleList"
            class="formFields"
            color="#7253CF"
            @change="getGameData()"
            :disabled="loading"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="2" class="py-0">
          <span class="textField-label">
            <v-checkbox
              color="#38A737"
              label="Ticket Sold Less Than 50%"
              value="low_ticket_sale"
              v-model="low_ticket_sale"
              @change="getGameData"
            ></v-checkbox>
          </span>
        </v-col>
        <!-- v-if="$vuetify.breakpoint.smAndUp"-->

        <v-col cols="12" md="5" v-if="$vuetify.breakpoint.smAndUp">
          <v-btn
            text
            style="
              border: 1px solid #38227a;
              border-radius: 10px;
              margin: 5px;
              width: 130px;
            "
            class="px-11 mr-5 text-capitalize"
            height="45px"
            @click="visitBulkImportHandler"
            v-if="this.adminAccess === 'Super Admin'"
          >
            <span style="color: #38227a">Bulk import</span>
          </v-btn>
          <v-btn
            color="#38227A"
            dark
            class="px-11 text-capitalize"
            style="border-radius: 10px; margin: 5px; width: 130px"
            height="45px"
            @click="toggleAddEditViewGameModal({ show: true, type: 'add' })"
            v-if="
              this.adminAccess === 'Super Admin' ||
              this.adminAccess == 'Sales Representative' ||
              this.adminAccess == 'Event Support Staff'
            "
          >
            <span>Add Game</span>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          v-if="
            this.adminAccess == 'Sales Representative' ||
            this.adminAccess == 'Event Support Staff'
          "
        >
          <v-btn
            color="#38227A"
            dark
            @click="ShowAll()"
            class="text-capitalize px-5"
            height="45px"
            style="border-radius: 10px; position: unset; margin-left: 20px"
            v-if="!currentState"
          >
            Show All
          </v-btn>
          <v-btn
            color="#38227A"
            dark
            @click="myEvents()"
            class="text-capitalize px-5"
            height="45px"
            style="border-radius: 10px; position: unset"
            v-if="currentState"
          >
            My events
          </v-btn>
        </v-col>
      </v-row>
      <v-col
        class="d-flex d-sm-none"
        cols="12"
        v-if="this.adminAccess === 'Super Admin' && $vuetify.breakpoint.xsOnly"
      >
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" class="mobile-menu"
              >mdi-menu</v-icon
            >
          </template>
          <v-list>
            <v-list-item-group active-class="active-class">
              <v-list-item
                @click="toggleAddEditViewGameModal({ show: true, type: 'add' })"
                v-if="
                  this.adminAccess === 'Super Admin' ||
                  this.adminAccess == 'Sales Representative' ||
                  this.adminAccess == 'Event Support Staff'
                "
              >
                <v-list-item-title>Add Game</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-list>
            <v-list-item-group active-class="active-class">
              <v-list-item
                @click="visitBulkImportHandler"
                v-if="this.adminAccess === 'Super Admin'"
              >
                <v-list-item-title>Bulk Import</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-col>
    </template>
    <v-data-table
      fixed-header
      hide-default-footer
      :headers="headers"
      :items="items"
      :loading="loading"
      class="elevation-8"
      loading-text="Loading... Please wait"
      mobile-breakpoint="0"
      :items-per-page="page_info.detail_count"
      :server-items-length="page_info.detail_count"
      :options.sync="dataOptions"
    >
      <!-- items template -->
      <template v-slot:item="props">
        <tr>
          <td class="text-center py-2">
            <div class="pl-2 text-center">
              <div class="textHeading">
                <a
                  style="color: black"
                  @click="visitGamePanelHandler(props.item)"
                >
                  {{ props.item.city.toUpperCase() }},
                  {{ props.item.state_name }}
                </a>
              </div>
              <div class="textHeading">
                {{ props.item.organization }}
              </div>
              <div>
                <v-chip small :class="`${props.item.status.toUpperCase()}`">
                  {{ props.item.status.toUpperCase() }}
                </v-chip>
              </div>
            </div>
            <div class="textHeading">{{ props.item.host_name }}</div>
            <div style="color: #757575">
              {{ props.item.host_email }}
            </div>
            <div v-if="props.item.pretix_slug != null">
              <img
                src="@/assets/thingsToDo/greenimage.png"
                width="15px"
                height="15px"
              />
            </div>
          </td>
          <td>
            <div
              style="margin-left: 15px; text-align-last: start"
              v-if="props.item.checklist_status.length == 4"
            >
              <div class="textHeadinglabels">
                {{ props.item.checklist_status[0].checklist.name }}-
                <span
                  class="textHeadinglabels"
                  v-if="props.item.checklist_status[0].status == 'Completed'"
                  style="color: #006400"
                  >{{
                    props.item.checklist_status[0].completion_date_str
                  }}</span
                >
                <span
                  class="textHeadinglabels"
                  v-if="props.item.checklist_status[0].status == 'Overdue'"
                  style="color: #ff0000"
                  >Overdue</span
                >
                <span
                  class="textHeadinglabels"
                  v-if="props.item.checklist_status[0].status == 'Pending'"
                  style="color: #d19900"
                  >Pending</span
                >
              </div>

              <div
                class="textHeadinglabels"
                v-if="
                  props.item.checklist_status[2].checklist.name == 'Assembly'
                "
              >
                Add Assembly -
                <span
                  class="textHeadinglabels"
                  v-if="
                    props.item.checklist_status[2].status == 'Completed' &&
                    props.item.assemblies != null
                  "
                  style="color: #006400"
                  >{{ props.item.assemblies[0].date }}</span
                >
                <span
                  class="textHeadinglabels"
                  v-else-if="
                    props.item.checklist_status[2].status == 'Completed' &&
                    props.item.assemblies == null
                  "
                  style="color: #006400"
                >
                  Completed</span
                >
                <span
                  class="textHeadinglabels"
                  v-if="props.item.checklist_status[2].status == 'Overdue'"
                  style="color: #ff0000"
                  >Overdue</span
                >
                <span
                  class="textHeadinglabels"
                  v-if="props.item.checklist_status[2].status == 'Pending'"
                  style="color: #d19900"
                  >Pending</span
                >
              </div>
              <div
                class="textHeadinglabels"
                v-if="props.item.checklist_status[3].checklist.name == 'Hotel'"
              >
                Hotel Update -
                <span
                  class="textHeadinglabels"
                  v-if="props.item.checklist_status[3].status == 'Completed'"
                  style="color: #006400"
                  >Completed</span
                >
                <span
                  class="textHeadinglabels"
                  v-if="props.item.checklist_status[3].status == 'Overdue'"
                  style="color: #ff0000"
                  >Overdue</span
                >
                <span
                  class="textHeadinglabels"
                  v-if="props.item.checklist_status[3].status == 'Pending'"
                  style="color: #d19900"
                  >Pending</span
                >
              </div>
            </div>

            <div
              style="margin-left: 15px; text-align-last: start"
              v-if="props.item.checklist_status.length == 5"
            >
              <div class="textHeadinglabels">
                {{ props.item.checklist_status[0].checklist.name }}-
                <span
                  class="textHeadinglabels"
                  v-if="props.item.checklist_status[0].status == 'Completed'"
                  style="color: #006400"
                  >{{
                    props.item.checklist_status[0].completion_date_str
                  }}</span
                >
                <span
                  class="textHeadinglabels"
                  v-if="props.item.checklist_status[0].status == 'Overdue'"
                  style="color: #ff0000"
                  >Overdue</span
                >
                <span
                  class="textHeadinglabels"
                  v-if="props.item.checklist_status[0].status == 'Pending'"
                  style="color: #d19900"
                  >Pending</span
                >
              </div>
              <div class="textHeadinglabels">
                {{ props.item.checklist_status[1].checklist.name }}-
                <span
                  class="textHeadinglabels"
                  v-if="props.item.checklist_status[1].status == 'Completed'"
                  style="color: #006400"
                  >{{
                    props.item.checklist_status[1].completion_date_str
                  }}</span
                >
                <span
                  class="textHeadinglabels"
                  v-if="props.item.checklist_status[1].status == 'Overdue'"
                  style="color: #ff0000"
                  >Overdue</span
                >
                <span
                  class="textHeadinglabels"
                  v-if="props.item.checklist_status[1].status == 'Pending'"
                  style="color: #d19900"
                  >Pending</span
                >
              </div>

              <div
                class="textHeadinglabels"
                v-if="
                  props.item.checklist_status[3].checklist.name == 'Assembly'
                "
              >
                Add Assembly -
                <span
                  class="textHeadinglabels"
                  v-if="
                    props.item.checklist_status[3].status == 'Completed' &&
                    props.item.assemblies != null
                  "
                  style="color: #006400"
                  >{{ props.item.assemblies[0].date }}</span
                >
                <span
                  class="textHeadinglabels"
                  v-else-if="
                    props.item.checklist_status[3].status == 'Completed' &&
                    props.item.assemblies == null
                  "
                  style="color: #006400"
                >
                  Completed</span
                >
                <span
                  class="textHeadinglabels"
                  v-if="props.item.checklist_status[3].status == 'Overdue'"
                  style="color: #ff0000"
                  >Overdue</span
                >
                <span
                  class="textHeadinglabels"
                  v-if="props.item.checklist_status[3].status == 'Pending'"
                  style="color: #d19900"
                  >Pending</span
                >
              </div>
              <div
                class="textHeadinglabels"
                v-if="props.item.checklist_status[4].checklist.name == 'Hotel'"
              >
                Hotel Update -
                <span
                  class="textHeadinglabels"
                  v-if="props.item.checklist_status[4].status == 'Completed'"
                  style="color: #006400"
                  >Completed</span
                >
                <span
                  class="textHeadinglabels"
                  v-if="props.item.checklist_status[4].status == 'Overdue'"
                  style="color: #ff0000"
                  >Overdue</span
                >
                <span
                  class="textHeadinglabels"
                  v-if="props.item.checklist_status[4].status == 'Pending'"
                  style="color: #d19900"
                  >Pending</span
                >
              </div>
            </div>
          </td>
          <td>
            <div
              class="textHeadinglabels"
              style="margin-left: 50px"
              v-if="
                props.item.presale_startdate &&
                props.item.presale_status == 'Updated'
              "
            >
              <span style="color: #f9c70c">{{
                props.item.presale_date_str
              }}</span>
            </div>
            <div
              class="textHeadinglabels"
              style="margin-left: 50px"
              v-else-if="
                props.item.presale_startdate &&
                props.item.presale_status == 'Completed'
              "
            >
              <span style="color: #4cbb17">{{
                props.item.presale_date_str
              }}</span>
            </div>
            <div
              class="textHeadinglabels"
              style="margin-left: 50px"
              v-else-if="
                props.item.presale_startdate &&
                props.item.presale_status == null
              "
            >
              <span style="color: black">{{
                props.item.presale_date_str
              }}</span>
            </div>
            <div class="textHeadinglabels" style="margin-left: 50px" v-else>
              unassigned
            </div>
          </td>
          <td>
            <div class="textHeading" v-if="props.item.team_name">
              {{ props.item.team_name }}
            </div>
            <div class="textHeading" v-else>Unassigned</div>
          </td>
          <td class="text-center">
            <div v-if="props.item.date != ''">
              <b> {{ props.item.game_date_str_2 }}</b>
            </div>
            <div v-else>null</div>
            <div v-if="props.item.start_time != ''">
              {{ converTime(props.item.start_time) }}
            </div>
            <div v-else>null</div>
          </td>
          <td class="text-center">
            <div class="textHeading" v-if="props.item.sales_rep_name">
              {{ props.item.sales_rep_name }}
            </div>
            <div class="textHeading" v-else>null</div>
          </td>
          <td class="text-center">
            <div class="textHeading" v-if="props.item.event_support_staff">
              {{ props.item.event_support_staff }}
            </div>
            <div class="textHeading" v-else>null</div>
          </td>
          <td class="text-center">
            <div class="textHeading">
              {{ props.item.total_tickets_sold }}/{{ props.item.all_seats }}
            </div>
            <v-btn
              color="#38227A"
              dark
              class="px-11 text-capitalize"
              style="
                border-radius: 10px;
                margin: 5px;
                width: 85px;
                font-size: 11px;
              "
              height="25px"
              @click="getTicketList(props.item)"
            >
              <span>Update Ticket</span>
            </v-btn>
          </td>
          <!--   <td class="text-center">
            <div
              class="textHeading"
              v-if="props.item.pretix_slug == null"
              style="color: red"
            >
              <img
                src="@/assets/thingsToDo/crossimg1.png"
                width="20px"
                height="20px"
              />
            </div>
            <div class="textHeading" v-else style="color: green">
              <img
                src="@/assets/thingsToDo/greenimage.png"
                width="20px"
                height="20px"
              />
            </div>
          </td>-->
          <td
            class="row-item"
            style="padding-left: 70px"
            v-if="props.item.campaign != null"
          >
            <b>Planning Meeting- {{ props.item.campaign[0].start_date_str }}</b
            >&nbsp;&nbsp;<span style="font-weight: 200"
              >{{ props.item.campaign[0].start_date_start_time_str }}-{{
                props.item.campaign[0].start_date_end_time_str
              }}</span
            >

            <br /><b
              >Kick Off Date- {{ props.item.campaign[0].kick_off_date_str }}</b
            >&nbsp;&nbsp;<span style="font-weight: 200"
              >{{ props.item.campaign[0].kick_off_start_time_str }}-{{
                props.item.campaign[0].kick_off_end_time_str
              }}</span
            >
            <br /><b
              >Graduation Date-
              {{ props.item.campaign[0].graduation_celebration_date_str }}</b
            >&nbsp;&nbsp;<span style="font-weight: 200"
              >{{ props.item.campaign[0].graduation_start_time_str }}-{{
                props.item.campaign[0].graduation_end_time_str
              }}</span
            >
          </td>

          <!--   <td class="text-center row-item" v-else>-</td>
              <td
              class="row-item"
              style="padding-left: 30px"
              v-if="props.item.assemblies != null"
            >
              <a style="color: black">
               <b> {{ props.item.assemblies[0].date }}</b>
                 <div v-if="props.item.assemblies[0].start_time != ''">
              {{props.item.assemblies[0].start_time}}
            </div>
</a>            </td>-->
          <td class="text-center row-item" v-else>-</td>
          <td class="text-center">
            <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="visitAssembly(props.item)"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="22"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <span>View Assembly</span>
              </v-tooltip>
            </v-chip>
            <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="
                toggleAddEditViewGameModal({
                  show: true,
                  type: 'edit',
                  id: props.item.id,
                })
              "
              v-if="
                props.item.user_type == 'admin' ||
                props.item.user_type == 'Sales Representative' ||
                props.item.user_type == 'Event Support Staff'
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="22"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-chip>

            <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="resendInvitation(props.item)"
              v-if="
                props.item.user_type == 'admin' ||
                props.item.user_type == 'Sales Representative' ||
                props.item.user_type == 'Event Support Staff'
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="25"
                  >
                    mdi-email
                  </v-icon>
                </template>
                <span>Resend Invitation</span>
              </v-tooltip>
            </v-chip>
            <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="visitCalendarPanelHandler(props.item)"
              v-if="
                props.item.user_type == 'admin' ||
                props.item.user_type == 'Sales Representative' ||
                props.item.user_type == 'Event Support Staff'
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="25"
                  >
                    mdi-calendar
                  </v-icon>
                </template>
                <span>View Calendar</span>
              </v-tooltip>
            </v-chip>
            <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="
                toggleDeleteGameModal({
                  Delete: true,
                  id: props.item.id,
                })
              "
              v-if="
                props.item.user_type == 'admin' ||
                props.item.user_type == 'Sales Representative' ||
                props.item.user_type == 'Event Support Staff'
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="25"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </v-chip>
            <!-- <v-menu offset-y left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        class="actionColumn-btn"
                        color="#F2F2F2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon size="25" color="#6B6B6B"
                          >mdi-dots-horizontal</v-icon
                        >
                      </v-chip>
                    </template>
                    <v-list class="menu-list moreOptionsStyle py-0">
                      <v-list-item-group class="py-0">
                        <v-list-item
                          style="min-height: 40px"

                        >
                          <v-icon left size="18">mdi-pencil</v-icon>
                          <v-list-item-title style="font-size: 14px">
                            Edit Campaign
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          style="
                            min-height: 40px;
                            border-top: 1px solid #d2d2d2;
                          "
                        >
                          <v-icon size="18" left>mdi-delete</v-icon>
                          <v-list-item-title style="font-size: 14px">
                            Delete Campaign
                          </v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu> -->
          </td>
        </tr>
      </template>
      <!-- items template -->
      <template v-slot:footer>
        <div>
          <v-divider></v-divider>
          <v-row no-gutters class="pt-6 px-2">
            <v-col
              class="mb-4"
              align-self="center"
              cols="12"
              xs="12"
              sm="6"
              md="4"
            >
              <div
                class="justify-center justify-md-start d-flex px-5 tableHeader-text"
              >
              
                <div class="alignSelf-center mr-3">Show</div>
                <div class="alignSelf-center mr-5 pb-2" style="width: 60px">
                  <v-select
                    class=""
                    :items="itemsPerPageList"
                    hide-details
                    v-model="page_info.detail_count"
                    dense
                  ></v-select>
                </div>

                <div class="alignSelf-center">
                  {{ page_info.page_number }}
                  of
                  {{ page_info.total_page }}
                </div>
              </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="4" md="4">
              <div>
                <v-pagination
                  :length="page_info.total_page"
                  v-model="page_info.page_number"
                  :total-visible="5"
                  color="#D30024"
                  class="pagination"
                >
                </v-pagination>
              </div>
            </v-col>
          </v-row>
        </div>
      </template>
    </v-data-table>
    <game-modal @reload="getGameData"></game-modal>
    <delete-game-modal @reload="getGameData"></delete-game-modal>
  </div>
</template>
<script>
import moment from "moment";
import { mapActions } from "vuex";
import {
  //   DATA,
  PAGE_INFO,
  ITEMS_PER_PAGE,
  PAGE_NUMBER,
  TOTAL_PAGE,
  SEARCH,
  SORTING_KEY,
  CAMPAIGN_ID,
  //   DATA,
} from "@/constants/APIKeys";
import { ROUTER_URL } from "@/constants/urls";
import Axios from "@/api/BaseAxios";
import {
  ADMIN_API_GAME_MANAGEMENT_LIST,
  API_GET_FISCAL_YEAR_RANGE,
  ADMIN_TICKET_TYPE_LIST,
  GET_API_FB_DOWNLOAD_GAME,
} from "@/constants/APIUrls";
import _ from "lodash";
export default {
  name: "GameTable",
  components: {
    GameModal: () => import("../AddEditGameModal/AddEditGameModal"),
    DeleteGameModal: () =>
      import("../DeleteGameConfirmation/DeleteGameConfirmation.vue"),
  },
  data() {
    return {
      gameCount: "",
      yearList: [],
      yearFilter: "",
      showFlag: false,
      currentState: false,
      adminAccess: localStorage.getItem("adminAccess"),
      dataOptions: { sortBy: [], sortDesc: [] },
      items: [],
      dates: [],
      dates1: [],
      menu: false,
      menu1: false,
      start_date: "",
      end_date: "",
      low_ticket_sale: "",
      presale_filter: "",
      presaleList: ["ALL", "Completed", "Updated"],
      loading: false,
      [PAGE_INFO]: {
        [PAGE_NUMBER]: 1,
        [TOTAL_PAGE]: 5,
        [ITEMS_PER_PAGE]: 50,
      },
      itemsPerPageList: [10, 15, 20, 30, 40, 50, 100],
      [SEARCH]: "",
      [SORTING_KEY]: "",
      campaignlistdata: [],
      headers: [
        {
          sortable: true,
          text: "Game Info",
          width: "8%",
          align: "center",
          value: "city",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Checklist Status",
          width: "21%",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: true,
          text: "Presale Date",
          width: "15%",
          align: "center",
          value: "presale_startdate",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: true,
          text: "Team  Name",
          align: "text-align-last:left",
          value: "team_name",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: true,
          text: "Game Date & Time",
          align: "center",
          value: "date",
          width: "11%",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: true,
          text: "Sales Rep",
          align: "center",
          value: "sales_rep",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: true,
          text: "Event Support",
          align: "center",
          value: "event_support",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Ticket Sold",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Wizfit Date",
          width: "20%",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Actions",
          align: "center",
          width: "9%",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
      ],
    };
  },
  computed: {
    dateRangeText: {
      get() {
        if (this.dates) {
          if (this.dates.length > 1) {
            let date = [
              moment(this.dates[0]).format("MM-DD-YYYY"),
              moment(this.dates[1]).format("MM-DD-YYYY"),
            ];
            return date.join(" - ");
          } else {
            return "";
          }
        } else {
          return "";
        }
        // return this.dates.join("  -  ");
      },
      set(newDates) {
        this.dates = newDates || [];
        return this.dates.length > 1 ? this.dates.join("  -  ") : "";
      },
    },

    dateRangeText1: {
      get() {
        if (this.dates1) {
          if (this.dates1.length > 1) {
            let date = [
              moment(this.dates1[0]).format("MM-DD-YYYY"),
              moment(this.dates1[1]).format("MM-DD-YYYY"),
            ];
            return date.join(" - ");
          } else {
            return "";
          }
        } else {
          return "";
        }
        // return this.dates1.join("  -  ");
      },
      set(newDates) {
        this.dates1 = newDates || [];
        return this.dates1.length > 1 ? this.dates1.join("  -  ") : "";
      },
    },
  },
  watch: {
    dates: function () {
      if (this.dates.length !== 1) {
        this.debounceGetGameManagementData();
      }
    },
    dates1: function () {
      if (this.dates1.length !== 1) {
        this.debounceGetGameManagementData();
      }
    },
    "dataOptions.sortBy": function () {
      if (this.dataOptions.sortBy.length !== 0) {
        this.sorting_key = this.dataOptions.sortDesc[0]
          ? `-${this.dataOptions.sortBy[0]}`
          : this.dataOptions.sortBy[0];
        this.getGameData();
      } else {
        this.sorting_key = "";
        this.getGameData();
      }
    },
   
    "page_info.page_number": function () {
      this.getGameData();
    },
    "page_info.detail_count": function () {
      this.getGameData();
    },
  },
  methods: {
    ...mapActions({
      toggleAddEditViewGameModal: "gameManagement/toggleModal",
      toggleDeleteGameModal: "gameManagement/toggleDeleteModal",
      showToast: "snackBar/showToast",
    }),

    visitBulkImportHandler() {
      this.$root.$refs.GameManagement.changeCondition(false);
    },
    visitGamePanelHandler(game) {
      if (this.adminAccess == "game") {
        const { id } = game;
        console.log(id);
        let routerData = this.$router.resolve({
          name: ROUTER_URL.gamePanel.children.gamehostassembly.name,
          query: { game_id: id },
        });
        window.open(routerData.href, "_blank");
      } else {
        const { id } = game;
        console.log(id);
        let routerData = this.$router.resolve({
          name: ROUTER_URL.gamePanel.children.dashboard.name,
          query: { game_id: id },
        });
        window.open(routerData.href, "_blank");
      }
    },
    visitCalendarPanelHandler(game) {
      const { id } = game;
      console.log(id);
      let routerData = this.$router.resolve({
        name: ROUTER_URL.gamePanel.children.gamecalendar.name,
        query: { game_id: id },
      });
      window.open(routerData.href, "_blank");
    },

    visitAssembly(game) {
      const { id } = game;
      console.log(id);
      let routerData = this.$router.resolve({
        name: ROUTER_URL.gamePanel.children.gameallassembly.name,
        query: { game_id: id },
      });
      window.open(routerData.href, "_blank");
    },
    filterDate(dates) {
      if (dates.length > 1) {
        this.$refs.menu.save(dates);
      }
    },
    filterDate1(dates1) {
      if (dates1.length > 1) {
        this.$refs.menu1.save(dates1);
      }
    },
    onSearchInput() {
  if (this.search.length >= 3) {
    this.debounceGetGameManagementData();
    this.performSearch();
  } else if (this.search.length === 0) {
    // Reset the list if the search box is empty
    this.results = this.debounceGetGameManagementData(); // Function to fetch or set the initial list
  } else {
    // Clear results if input is less than 3 characters
    this.results = [];
  }
},
    getGameData() {
      this.loading = true;
      const successHandler = (res) => {
        console.log(res.data);
        this.items = res.data.game_list;
        this.gameCount = res.data.game_count;
        this.page_info[PAGE_NUMBER] = res.data[PAGE_INFO][PAGE_NUMBER];
        //  alert(res.data[PAGE_INFO][PAGE_NUMBER])
        this.page_info[TOTAL_PAGE] = res.data[PAGE_INFO][TOTAL_PAGE];
        this.page_info[ITEMS_PER_PAGE] = res.data[PAGE_INFO][ITEMS_PER_PAGE];
        // alert(res.data[PAGE_INFO][ITEMS_PER_PAGE])
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData[PAGE_NUMBER] = this.page_info[PAGE_NUMBER];
      formData[ITEMS_PER_PAGE] = this.page_info.detail_count;
      formData[SORTING_KEY] = this.sorting_key;
      formData[SEARCH] = this.search;
      if (this.showFlag == true) {
        formData.show_all = "show_all";
      }
      if (this.dates1.length == 2) {
        let filter1 = {
          start_date: moment(this.dates1[0]).format("MM-DD-YYYY"),
          end_date: moment(this.dates1[1]).format("MM-DD-YYYY"),
        };
        formData["creation_filter"] = filter1;
      } else {
        formData["creation_filter"] = {};
      }
      if (this.dates) {
        if (this.dates.length == 2) {
          let filter = {
            start_date: moment(this.dates[0]).format("MM-DD-YYYY"),
            end_date: moment(this.dates[1]).format("MM-DD-YYYY"),
          };
          formData["filter"] = filter;
        } else {
          formData["filter"] = {};
        }

        if (this.yearFilter) {
          formData.fiscal_year = this.yearFilter;
        }
        if (this.presale_filter) {
          let presalefilter1 = {
            presale_status: this.presale_filter,
          };
          formData["presale_filter"] = presalefilter1;

          if (this.presale_filter == "ALL") {
            location.reload();
          }
        }
        if (this.low_ticket_sale) {
          formData.low_ticket_sale = this.low_ticket_sale;
        }
      }
      Axios.request_GET(
        ADMIN_API_GAME_MANAGEMENT_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    ShowAll() {
      this.showFlag = true;
      this.currentState = true;
      this.getGameData();
    },
    myEvents() {
      location.reload();
    },
    converTime(value) {
      if (value) {
        var temp = value.split(":");
        if (parseInt(temp[0]) > 12) {
          value = parseInt(temp[0]) - 12 + ":" + temp[1] + " PM";
        } else {
          value = temp[0] + ":" + temp[1] + " AM";
        }
      }

      return value;
    },

    resendInvitation(item) {
      const successHandler = () => {
        this.showToast({
          message: "Invitation sent",
          color: "s",
        });
      };

      const failureHandler = () => {};
      let formData = {};
      formData["id"] = item.id;
      Axios.request_GET(
        "/game/resend_email/",
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getYearList() {
      const self = this;
      const successHandler = (res) => {
        const data = res.data;
        self.yearList = data.year_list;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.loading = false;
      };
      const finallyHandler = () => {};
      const formJson = {};
      return Axios.request_GET(
        API_GET_FISCAL_YEAR_RANGE,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        finallyHandler
      );
    },
    viewCampaignHandler() {
      for (var i = 0; i < this.campaignlistdata.length; i++) {
        if (this.campaignlistdata[i].campaign != null) {
          for (var j = 0; j < this.campaignlistdata[i].campaign.length; j++) {
            if (this.campaignlistdata[i].campaign[j].district != null) {
              if (
                this.campaignlistdata[i].campaign[j].campaign_type ===
                "district"
              ) {
                let routerData = this.$router.resolve({
                  name: "districtDashboard",
                  query: {
                    district: this.campaignlistdata[i].campaign[j].district,
                    [CAMPAIGN_ID]: this.campaignlistdata[i].campaign[j].id,
                  },
                });

                window.open(routerData.href, "_blank");
              }
            }
            if (this.campaignlistdata[i].campaign[j].school != null) {
              if (
                this.campaignlistdata[i].campaign[j].campaign_type === "school"
              ) {
                let routeData = this.$router.resolve({
                  name: "schoolDashboard",
                  query: {
                    school: this.campaignlistdata[i].campaign[j].school,
                    [CAMPAIGN_ID]: this.campaignlistdata[i].campaign[j].id,
                  },
                });
                window.open(routeData.href, "_blank");
              }
            }
          }
        }
      }
    },
    getTicketList(item) {
      this.loading = true;
      const successHandler = (res) => {
        this.items = res.data.ticket_list;
        this.loading = false;
        this.getGameData();
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["game_id"] = item.id;
      Axios.request_GET(
        ADMIN_TICKET_TYPE_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getReport() {
      const self = this;
      self.btnLoading = true;
      const successHandler = (res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let fileName = res.headers["content-disposition"]
          .split(";")[1]
          .split("=")[1]
          .split('"')[1];
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        self.btnLoading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.btnLoading = false;
      };
      let formData = {};
      Axios.request_GET_BLOB(
        GET_API_FB_DOWNLOAD_GAME,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        true
      );
    },
     route() {
       this.$router.push({
      name: ROUTER_URL.adminPanel.children.gamecsvUpload.name,
      });
      //this.$root.$refs.adminLayout.route("school");
    },
  },
  mounted() {
    this.debounceGetGameManagementData = _.debounce(this.getGameData, 500);
    //  this.getGameData();
    this.getYearList();
  },
  created() {},
};
</script>
<style scoped>
.v-data-table.elevation-8.v-data-table--fixed-header.v-data-table--has-top.theme--light {
  border-radius: 6px;
}
.alignSelf-center {
  align-self: center;
}
* {
  font-family: Lato;
}
.v-btn >>> span {
  letter-spacing: 0px;
  font-weight: 600;
}
.user-initials-text {
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 315%;
  color: white;
  letter-spacing: 0px;
}
.textHeading {
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
}
.textHeadinglabels {
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.ONGOING {
  background-color: rgba(231, 111, 0, 0.12);
  color: #e76f00;
  border-radius: 10px;
  font-weight: 700;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.UPCOMING {
  background-color: rgba(0, 133, 255, 0.12);
  color: #0085ff;
  border-radius: 10px;
  font-weight: 700;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.COMPLETED {
  background-color: rgba(6, 118, 5, 0.12);
  color: #067605;
  border-radius: 10px;
  font-weight: 700;
}
span.v-chip {
  border-radius: 4px;
  margin-left: 12px;
}
</style>
<style>
.tableHeader-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 17px !important;
  line-height: 161.5% !important;
  color: #1d1d1d !important;
  padding: 0px 4px !important;
}
.tableHeader-bg {
  background: #e7e5ed !important;
}
span.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
  height: 45px;
  width: 38px;
  cursor: pointer;
}
.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
  
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}
</style>
