<style>
.scrolldiv {
  overflow-y: 43%;
}
</style>
<template>
  <v-dialog
    v-model="show"
    max-width="750px"
    persistent
    content-class="scrolldiv"
  >
    <div class="text-center" v-if="loading" style="min-height: 950px"></div>
    <v-card v-else>
      <v-card-title :class="$vuetify.breakpoint.xsOnly ? ' px-0' : ''">
        <div
          style="border-bottom: 1px solid #cccccc; display: flex; width: 100%"
          v-if="!edit"
        >
          <v-tabs v-model="selectedTab" color="#D30024">
            <v-tab
              v-for="(item, i) in tabs"
              :key="i"
              class="text-capitalize"
              v-text="item.text"
            ></v-tab>
          </v-tabs>

          <v-spacer></v-spacer>
          <v-icon @click="closepopup">mdi-close</v-icon>
        </div>
        <div
          v-else
          style="display: flex; width: 100%; border-bottom: 1px solid #e8e8e8"
          class="py-4"
        >
          <v-icon size="32" color="#2D2D2D" @click="switchForm"
            >mdi-arrow-left</v-icon
          >
          <div class="ml-2">Edit your “Invite Email”</div>
          <v-spacer></v-spacer>
          <v-icon @click="show = false" size="32" color="#2D2D2D"
            >mdi-close</v-icon
          >
        </div>
      </v-card-title>
      <v-card-text :class="selectedTab === 1 ? 'px-0' : ''">
        <div v-if="selectedTab === 0">
          <div v-if="!edit">
            <v-form ref="emailForm" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <div style="width: 100%; height: auto">
                    <div style="border-radius: 6px; height: 130px"></div>
                  </div>
                  <img
        :class="{'hover-effect': isHovering, 'clicked-effect': isClicked}"
        src="@/assets/thingsToDo/button-031.png"
        width="40"
        height="40"
        style="
          position: absolute;
          top: 188px;
          right: 2%;
          width: 20%;
          height: auto;
          cursor: pointer;
        "
        @mouseenter="isHovering = true"
        @mouseleave="isHovering = false"
        @click="handleClick"
      />
                </v-col>
              </v-row>
              <v-row>
                <label class="titleShare"
                  >Share your page and progress by adding an email.<br /><span
                    class="titleSharess"
                    >Get Donations and Earn Prizes.</span
                  ></label
                >
              </v-row>
              <strong
                style="margin-left: 54px; margin-bottom: 4px; font-size: 16px"
              ></strong>
              <v-row
                no-gutters
                style="margin-left: 30px"
                v-for="(item, i) in inputData"
                :key="i"
              >
                <v-col
                  cols="4"
                  :class="$vuetify.breakpoint.smAndUp ? 'ml-3' : ' ml-2'"
                  v-if="seen"
                >
                  <v-text-field
                    label="Email"
                    outlined
                    dense
                    v-model="item.email"
                    class="formFields"
                    :rules="[rules.email]"
                    color="#7253CF"
                    :disabled="formLoading"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  cols="4"
                  :class="$vuetify.breakpoint.smAndUp ? 'ml-4' : 'ml-2'"
                  v-if="seen"
                >
                  <v-text-field
                    label="Name (Optional)"
                    outlined
                    dense
                    v-model="item.nick_name"
                    class="formFields"
                    color="#7253CF"
                    :disabled="formLoading"
                    ref="addplayer"
                    id="addplayer"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="2" class="d-flex justify-center" v-if="i > 0">
                  <v-btn icon @click="deleteInputRow(i)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-btn
                @click="submitForm"
                class="text-capitalize sendBtn"
                dark
                color="#7253CF"
                v-if="this.$route.matched[0].path.substring(1) !== 'teacher'"
                :disabled="formLoading"
                >Send Email</v-btn
              >
              <v-btn
                @click="submitTeacherForm"
                class="text-capitalize sendBtn"
                dark
                color="#7253CF"
                v-if="this.$route.matched[0].path.substring(1) == 'teacher'"
                :disabled="formLoading"
                >Send Email</v-btn
              >
              <!--  <img
                src="@/assets/thingsToDo/sendemail.png"
                width="20"
                height="20"
                style="position: absolute; right: 40%; width: 20%; height: auto"
                @click="submitForm"
              v-if="this.$route.matched[0].path.substring(1) !== 'teacher'"/>
               <img
                src="@/assets/thingsToDo/sendemail.png"
                width="20"
                height="20"
                style="position: absolute; right: 40%; width: 20%; height: auto"
                @click="submitTeacherForm"
              v-if="this.$route.matched[0].path.substring(1) == 'teacher'"/>-->
            </v-form>
          </div>
          <div v-else>
            <edit-template-form></edit-template-form>
          </div>
        </div>
        <div v-else>
          <previous-invite-list></previous-invite-list>
        </div>
      </v-card-text>
      <v-card-actions class="pb-8 px-6">
        <div
          style="width: 100%"
          class="d-flex justify-end"
          v-if="selectedTab === 0"
        ></div>
      </v-card-actions>
    </v-card>
    <player-modal></player-modal>
  </v-dialog>
</template>
<script>
import "quill/dist/quill.snow.css";
import Axios from "@/api/BaseAxios";
import RULES from "@/common/fieldRules";
import {
  INVITE_VIA_MAIL_POST,
  INVITE_VIA_MAIL_GET_TEMPLATE,
  API_ADMIN_STUDENT_MANAGEMENT_GET,
  INVITE_VIA_MAIL_TEACHER_POST,
} from "@/constants/APIUrls";

import { mapGetters, mapActions } from "vuex";
export default {
  name: "InviteViaModal",
  components: {
    PreviousInviteList: () =>
      import("./PreviousInviteList/PreviousInviteTemplate"),
    EditTemplateForm: () => import("./EditTemplate/EditTemplate"),
    PlayerModal: () => import("./PlayerModal/PlayerModal"),
  },
  data() {
    return {
      rules: RULES,
        isHovering: false,
      isClicked: false,
      seen: false,
      selectedTab: 0,
      edit: false,
      loading: false,
      formLoading: false,
      student_id: null,
      teacher_id: "",
      campaign_id: "",
      studentFirstName: "",
      studentproilepic: "",
      max: 14,
      tabs: [
        { text: "Invite Someone", value: "invite_someone" },
        { text: "Previous Invites", value: "previous_invites" },
      ],
      inputData: [{ nick_name: "", email: "", phone: "" }],
      emaillSubject: "",
      emailBody: "",
      emailTemplate: {},
      quillTemplate: "",
      editorOption: {
        placeholder: "Message Content",
        readOnly: true,
        theme: "snow",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            [{ direction: "rtl" }], // text direction
            ["clean"],
          ],
        },
      },
    };
  },
  created() {
    this.$root.$refs.InviteViaMail = this;
  },
  computed: {
    ...mapGetters({
      showGetter: "publicDonationPage/getInviteViaMail",
      getCampaign: "publicDonationPage/getCampaign",
      selectedCampaign: "campaign/getSelectedCampaign",
      panel() {
        return this.$route.matched[0].path.substring(1);
      },
    }),
    getCampaignDetail() {
      return this.getCampaign;
    },
    studentDetail() {
      return this.getCampaign.student_detail;
    },
    selectedCampaignData() {
      return this.selectedCampaign;
    },
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        this.toggleModal({ show: value });
      },
    },
  },
  watch: {
    show(value) {
      if (value) {
        setTimeout(() => {
          this.openModal();
        }, 100);
      }
    },
  },
  methods: {
    ...mapActions({
      toggleModal: "publicDonationPage/toggleInviteMail",
      toggleStoreDetail: "publicDonationPage/toggleStoreDetail",
      togglePlayerModal: "publicDonationPage/togglePlayerModal",
      showToast: "snackBar/showToast",
    }),
    openModal() {
      //alert(this.$route.matched[0].path.substring(1) == 'teacher')
      // alert(this.$route.matched[0].path.substring(1) == 'student')
      if (this.$route.matched[0].path.substring(1) !== "teacher") {
        this.getTemplate();
      }
    },

    closeModal() {
      this.edit = false;
      this.inputData = [{ nick_name: "", email: "" }];
      this.selectedTab = 0;
      this.emaillSubject = "";
      this.emailBody = "";
      this.emailTemplate = {};
      this.formLoading = false;
    },
    openPlayeModal(id) {
      this.togglePlayerModal({ show: true, type: "add", id: id });
    },
    editPlayerModal(obj, id) {
      console.log(obj, id);
      this.togglePlayerModal({ show: true, type: "edit", id: id, detail: obj });
    },
    updatePlayer(obj, id) {
      console.log(obj, id);
      switch (id) {
        case 1:
          this.player1 = obj;
          break;
        case 2:
          this.player2 = obj;
          break;
        case 3:
          this.player3 = obj;
          break;
        case 4:
          this.player4 = obj;
          break;
        case 5:
          this.player5 = obj;
          break;
        default:
          break;
      }
    },
    switchForm() {
      this.loading = true;
      this.edit = !this.edit;
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },

    addInputRow() {
      this.seen = true;
      this.inputData.push({ nick_name: "", email: "" });
    },
    addInputRow1() {
      this.seen = true;
      this.inputData.push({ nick_name: "", email: "" });
      this.inputData.push({ nick_name: "", email: "" });
      this.inputData.push({ nick_name: "", email: "" });
      this.inputData.push({ nick_name: "", email: "" });
    },
    deleteInputRow(index) {
      this.inputData.splice(index, 1);
    },
    sendTemplateDetail() {
      console.log(this.emailTemplate);
      this.toggleStoreDetail({ emailDetail: this.emailTemplate });
      this.switchForm();
    },
    storeTemplateDetail(body, subject) {
      console.log("in here waps");
      this.emailBody = body;
      this.emaillSubject = subject;
      this.emailTemplate.email_body = body;
      this.switchForm();
    },
    getTemplate() {
      this.loading = true;
      const successHandler = (res) => {
        console.log(res.data);
        this.emailTemplate = res.data.email_template_detail;
        this.quillTemplate = this.emailTemplate.email_body_preview;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["student_id"] = this.student_id;
      formData["campaign_id"] = this.getCampaignDetail.campaign_detail.id;
      formData["email_template_type"] = "invite_mail";
      Axios.request_GET(
        INVITE_VIA_MAIL_GET_TEMPLATE,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
     handleClick() {
      this.isClicked = true;

      // Reset the clicked state after 300ms
      setTimeout(() => {
        this.isClicked = false;
      }, 300);

      this.addInputRow();
    },
    submitForm() {
      this.formLoading = true;
      const successHandler = (res) => {
        console.log(res.data);
        this.formLoading = false;
        this.inputData = [{ nick_name: "", email: "" }];
        this.addInputRow1();
          this.toggleModal({ show: false });
            this.showToast({
          message: "Invite Sent",
          color: "s",
        });
       // location.reload()
      };
      const failureHandler = (res) => {
        console.log(res);
        this.formLoading = false;
      };
      let formData = {};
      formData["invite_by"] = "student";
      formData["platform"] = "email";
      formData["campaign"] = this.getCampaignDetail.campaign_detail.id;
      formData["student"] = this.student_id;
      formData["email_list"] = this.inputData;
      if (this.emailBody && this.emaillSubject) {
        formData["message"] = this.emailBody;
        formData["subject"] = this.emaillSubject;
      } else {
        formData["email_template_id"] = this.emailTemplate.id;
      }
      Axios.request_POST(
        INVITE_VIA_MAIL_POST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    submitTeacherForm() {
      this.formLoading = true;
      const successHandler = (res) => {
        console.log(res.data);
        this.formLoading = false;
        this.inputData = [{ nick_name: "", email: "" }];
        this.addInputRow1();
        //  this.toggleModal({ show: false });
        this.showToast({
          message: "Invite Sent",
          color: "s",
        });
        //  location.reload()
      };
      const failureHandler = (res) => {
        console.log(res);
        this.formLoading = false;
      };
      let formData = {};
      formData["invite_by"] = "teacher";
      formData["platform"] = "email";
      formData["campaign"] = this.selectedCampaignData.campaignData.id;
      formData["teacher"] = this.selectedCampaignData.organizationData.id;
      // alert(formData["teacher"])
      formData["email_list"] = this.inputData;
      if (this.emailBody && this.emaillSubject) {
        formData["message"] = this.emailBody;
        formData["subject"] = this.emaillSubject;
      } else {
        formData["email_template_id"] = 125;
      }
      Axios.request_POST(
        INVITE_VIA_MAIL_TEACHER_POST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getStudentDetail() {
      const self = this;
      self.loading = true;

      const successHandler = (res) => {
        const data = res.data;
        console.log("studentdata", data);
        this.studentFirstName = data.student_detail.student_first_name;
        console.log("studentFirstName##########", this.studentFirstName);
        this.studentproilepic = data.student_detail.profile_pic;
        console.log("studentproilepic@@@@@@", this.studentproilepic);
        self.studentLastName = data.student_detail.student_last_name;
        self.parentName = data.student_detail.parent_name;
        self.parentEmail = data.student_detail.parent_email;
        self.studentGoal = data.student_detail.student_goal;
        self.grade = data.student_detail.teacher;
        self.jerseySize = data.student_detail.jersey_size;
        // self.shortSize = data.student_detail.shorts_size;
        self.specialMessage = data.student_detail.student_special_message;
        self.loading = false;
        this.campaign_type = data.student_detail.campaign_type;
      };
      const failureHandler = (res) => {
        const data = res.data;
        console.log(data);
        self.loading = false;
      };

      let formData = {};
      formData["student_id"] = this.student_id;

      console.log(formData);
      Axios.request_GET(
        API_ADMIN_STUDENT_MANAGEMENT_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true
      );
    },
    closepopup() {
      this.toggleModal({ show: false });
      location.reload();
      // this.$router.go(-1)
    },
    starttimer() {
      setTimeout(() => {
        const elem = document.getElementById("addplayer");
        elem.click();
      }, 1);
    },
  },
  mounted() {
    this.student_id = this.$route.query.student;
    this.teacher_id = this.$route.query.teacher;
    this.campaign_id = this.$route.query.campaign_id;
    console.log(" this.student_id", this.student_id);
    if (this.student_id) {
      this.getStudentDetail();
    }
    this.addInputRow1();
  },
};
</script>
<style scoped>
* {
  font-family: Lato;
}
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}
.quill-editor {
  height: 150px;
  font-family: Lato;
}

.playerDiv {
  cursor: pointer;
  width: 100%;
}
.plusImg {
  float: right;
  position: relative;
  top: 30px;
  right: 15px;
  width: 20px;
}
.button {
  background-color: #1c87c9;
  -webkit-border-radius: 60px;
  border-radius: 60px;
  border: none;
  color: #eeeeee;
  cursor: pointer;
  display: inline-block;
  font-family: sans-serif;
  font-size: 20px;
  padding: 5px 15px;
  text-align: center;
  text-decoration: none;
}
@keyframes glowing {
  0% {
    background-color: #2ba805;
    box-shadow: 0 0 5px #2ba805;
  }
  50% {
    background-color: #49e819;
    box-shadow: 0 0 20px #49e819;
  }
  100% {
    background-color: #2ba805;
    box-shadow: 0 0 5px #2ba805;
  }
}
.button {
  animation: glowing 1300ms infinite;
}
@media (min-width: 1281px) {
  .titleShare {
    margin-top: -118px;
    margin-left: 134px;
    font-size: 18px;
  }
}
@media (max-width: 1281px) {
  .titleShare {
    margin-top: -118px;
    margin-left: 50px;
    font-size: 18px;
  }
}
@media (min-width: 1281px) {
  .titleSharess {
    margin-top: -118px;
    margin-left: 80px;
    font-size: 17px;
  }
}
@media (max-width: 1281px) {
  .titleSharess {
    margin-top: -118px;

    font-size: 17px;
  }
}

@media (min-width: 1281px) {
  .sendBtn {
    position: absolute;
    right: 48%;
    width: 20%;
    font-size: 19px;
    font-weight: 600;
  }
}
@media (max-width: 1281px) {
  .sendBtn {
    position: absolute;
    right: 32%;
    width: 42%;
    font-size: 19px;
    font-weight: 600;
  }
}
.hover-effect {
  filter: brightness(1.2); /* Brightens the button when hovered */
  transform: scale(1.1); /* Slight zoom effect */
  transition: all 0.3s ease; /* Smooth transition for hover effect */
}

.clicked-effect {
  filter: brightness(0.9); /* Darkens slightly when clicked */
  transform: scale(0.9); /* Shrinks slightly on click */
  transition: all 0.1s ease; /* Faster transition for click feedback */
}
</style>
