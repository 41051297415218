<template>
  <v-dialog
    v-model="show"
    max-width="1080px"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-toolbar
        dark
        color="#7253CF"
        :height="$vuetify.breakpoint.smAndUp ? '128' : '75px'"
        v-if="$vuetify.breakpoint.smAndUp"
      >
        <div class="ml-2 ml-sm-10">
          <v-img
            :width="$vuetify.breakpoint.smAndUp ? '102' : '56'"
            src="@/assets/publicDonationPage/makeDonationModalLogo.svg"
          ></v-img>
        </div>
        <v-spacer></v-spacer>
        <v-toolbar-title class="modalTitle mr-10"
          ><div>Make Donation</div></v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn class="align-self-baseline" icon @click="show = false">
          <v-icon size="32">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form
        ref="donationForm"
        lazy-validation
        v-if="$vuetify.breakpoint.smAndUp"
      >
        <v-card-text style="height: 610px; overflow-y: auto">
          <v-row class="pb-5 pt-5">
            <v-col
              :class="$vuetify.breakpoint.smAndUp ? 'py-0 px-10' : 'px-10'"
            >
              <div class="sectionTitle mb-5" style="width: fit-content">
                <span class="textField-label">Payment Information</span>
                <v-divider
                  class="sectionTitleUnderline mt-1"
                  style=""
                ></v-divider>
              </div>
              <v-row>
                <v-col
                  v-if="false"
                  cols="12"
                  style="position: relative; width: 100%"
                >
                  <v-text-field
                    placeholder="Select student/teacher/school/district"
                    prepend-inner-icon="mdi-magnify"
                    outlined
                    dense
                    hide-details
                    v-model="searchQuery"
                    :loading="searchLoading"
                  ></v-text-field>
                  <v-expand-transition>
                    <v-card
                      v-show="searchQuery"
                      light
                      style="position: absolute; z-index: 5; overflow-y: auto"
                      class="card-searchResult mt-3"
                      min-width="90%"
                      max-height="250px"
                      elevation="20"
                    >
                      <v-list
                        height="100%"
                        style="overflow-y: auto"
                        class="pa-0"
                        three-line
                      >
                        <template>
                          <v-list-item
                            @click="searchItemClickHandler(item)"
                            v-for="(item, index) in searchList"
                            :key="index"
                          >
                            <v-list-item-avatar class="align-self-center">
                              <v-img
                                v-if="item.profile_pic !== null"
                                :src="item.profile_pic"
                              ></v-img>
                              <v-avatar v-else color="red" size="40">
                                <span class="white--text text-h6">{{
                                  getInitials(item.name)
                                }}</span>
                              </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>
                                <span class="searchList-title">{{
                                  item.name
                                }}</span>
                                <v-chip
                                  small
                                  class="mx-2 mt-n1"
                                  :class="item.user_type"
                                  style="font-size: 17px"
                                >
                                  {{ item.user_type }}
                                </v-chip>
                              </v-list-item-title>
                              <v-list-item-subtitle
                                class="searchList-secondryText"
                                v-if="item.user_type === 'student'"
                                v-html="
                                  `${item.grade_teacher} (Grade ${item.grade_title})`
                                "
                              ></v-list-item-subtitle>
                              <v-list-item-subtitle
                                class="searchList-secondryText"
                                v-if="item.user_type === 'student'"
                                v-html="item.school_name"
                              ></v-list-item-subtitle>
                              <v-list-item-subtitle
                                class="searchList-secondryText"
                                v-if="item.user_type === 'teacher'"
                                v-html="
                                  `Grade ${item.grade_title} (${item.school_name})`
                                "
                              ></v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider
                            v-if="index < searchList.length - 1"
                            :key="index + 'div'"
                          ></v-divider>
                        </template>
                      </v-list>
                    </v-card>
                  </v-expand-transition>
                </v-col>
                <v-col cols="12">
                  <span class="textField-label">You're donating to</span>
                  <v-divider></v-divider>
                  <v-list-item
                    v-show="selectedSearch.name"
                    style="z-index: 1; margin-top: -4%"
                  >
                    <v-list-item-avatar class="align-self-center custom-avatar">
                      <v-img
                        v-if="
                          selectedSearch.profile_pic !==
                          'https://harlemwizardsinabox-prod-bucket.s3.us-east-1.amazonaws.com/media/Img/student.png'
                        "
                        :src="selectedSearch.profile_pic"
                      ></v-img>
                      <v-avatar v-else color="red" size="60">
                        <span class="white--text text-h6">{{
                          getInitials(selectedSearch.name)
                        }}</span>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span class="searchList-title">{{
                          selectedSearch.name
                        }}</span>
                        <div style="margin-left: -3%; margin-top: 2%">
                          <v-chip
                            small
                            class="mx-2 mt-n1"
                            :class="selectedSearch.user_type"
                            style="font-size: 17px"
                            v-if="selectedSearch.user_type !== 'student'"
                          >
                            school
                          </v-chip>
                          <v-chip
                            small
                            class="mx-2 mt-n1"
                            :class="selectedSearch.user_type"
                            style="font-size: 17px"
                            v-else
                          >
                            {{ selectedSearch.user_type }}
                          </v-chip>
                        </div>
                      </v-list-item-title>
                      <v-list-item-subtitle
                        class="searchList-secondryText"
                        v-if="selectedSearch.user_type === 'student'"
                        v-html="selectedSearch.school_name"
                      ></v-list-item-subtitle>
                      <v-list-item-subtitle
                        class="searchList-secondryText"
                        v-if="selectedSearch.user_type === 'teacher'"
                        v-html="
                          `Grade ${selectedSearch.grade_title} (${selectedSearch.school_name})`
                        "
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider style="margin-top: -7%"></v-divider>
                </v-col>
              </v-row>
              <div
                :class="{ clamp: !showFullMessage }"
                style="font-size: 15px; overflow: hidden"
                v-html="specialMessage"
                v-if="selectedSearch.user_type === 'student'"
              ></div>

              <div
                :class="{ clamp: !showFullMessage }"
                style="font-size: 15px; overflow: hidden"
                v-else
              >
                <div v-html="getDetailMessage"></div>
              </div>
              <!-- Toggle Read More / Show Less button -->
              <v-btn
                text
                @click="toggleMessage"
                v-if="$vuetify.breakpoint.xsOnly"
              >
                {{ showFullMessage ? "Show Less" : "Read More" }}
              </v-btn>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col class="py-0 px-10">
              <div class="sectionTitle mb-5" style="width: fit-content">
                <span>Donor Information</span>
                <v-divider
                  class="sectionTitleUnderline mt-1"
                  style=""
                ></v-divider>
              </div>
              <v-row>
                <v-col cols="12" sm="6">
                  <span class="textField-label">First Name</span>
                  <v-text-field
                    v-model="donorFirstName"
                    outlined
                    dense
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  :class="$vuetify.breakpoint.xsOnly ? 'pt-0' : ''"
                >
                  <span class="textField-label">Last Name</span>
                  <v-text-field
                    v-model="donorLastName"
                    outlined
                    dense
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="mt-n7">
                  <span class="textField-label">Email</span>
                  <v-text-field
                    v-model="donorEmail"
                    outlined
                    dense
                    :rules="[rules.required, rules.email]"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="mt-n7">
                  <span class="textField-label">Phone</span>
                  <v-text-field
                    v-model="donorPhone"
                    outlined
                    dense
                    :rules="[rules.required]"
                    @keypress="isNumber($event)"
                  ></v-text-field>
                </v-col>
                <span class="thankyoumsg"
                  >Thank you for entering your information. This will allow us
                  to remove you from recurring donation request.</span
                >
                <br />
                <br />
                <v-col cols="12" class="mt-n7">
                  <span class="textField-label">Special message from you</span>
                  <v-textarea
                    v-model="donorSpecialMsg"
                    outlined
                    rows="2"
                    hide-details
                    placeholder="Write special message"
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <span class="textField-label"
                    >How much you want to donate?</span
                  >
                  <v-text-field
                    v-model="amount"
                    prepend-inner-icon="mdi-currency-usd"
                    outlined
                    dense
                    type="number"
                    :rules="[rules.required]"
                    v-on:keyup="validate_amount('amount')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" class="py-0 mt-n7">
                  <span class="textField-label">
                    <v-chip-group
                      v-model="donationSelection"
                      active-class="deep-purple--text text--accent-4"
                    >
                      <v-chip
                        v-for="size in donationSuggestions"
                        :key="size"
                        :value="size"
                        label
                        :class="$vuetify.breakpoint.smAndUp ? 'px-5' : 'px-3'"
                        @click="amount = size"
                      >
                        <span class="donationSuggestion-item">
                          {{ `$${size}` }}
                        </span>
                      </v-chip>
                    </v-chip-group>
                  </span>
                </v-col>
                <v-col
                  cols="12"
                  class="py-0"
                  v-if="this.campaign.campaign_detail.id != 204"
                >
                  <span class="textField-label">
                    <v-checkbox
                      v-model="checkbox"
                      color="#38A737"
                      :label="`I want to pay the platform fees too (${stripeGatewayPercentage}%)`"
                    ></v-checkbox>
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-col cols="12">
            <!--  <span class="textField-label">
              <v-list-item-avatar class="align-self-center">
                <v-img
                  src="@/assets/thingsToDo/pledge.png"
                  height="30"
                  width="20"
                ></v-img> </v-list-item-avatar
              >Pledge</span
            >-->
            <v-divider></v-divider>
            <!--  <v-list-item v-show="selectedSearch.name" style="z-index: 1">
              <span class="textField-label">
                <v-checkbox v-model="pledge" color="#38A737"></v-checkbox>
              </span>
              <v-list-item-title style="margin-left: 20px">
                <span class="searchList-title"
                  >I pledge to donate 20% extra when
                  {{ selectedSearch.name }} completes WizFit video
                  challenge</span
                >
              </v-list-item-title>
            </v-list-item>-->
          </v-col>
        </v-card-text>
        <v-card-actions class="pt-5 pb-7 d-flex justify-center">
          <v-btn
            class="payDonation-btn py-7 pl-9 pr-6"
            color="#38A737"
            :loading="payDonationBtnLoading"
            :disabled="payDonationBtnDisabled"
            :dark="!payDonationBtnDisabled"
            right
            @click="payDonationHandler()"
          >
            <v-icon class="mr-5" size="30" left dark> mdi-lock </v-icon>
            <span>Securely Pay ${{ finalDonationAmount }}</span>
            <v-icon class="ml-7" size="38" right dark> mdi-arrow-right </v-icon>
          </v-btn>
        </v-card-actions>
      </v-form>
      <div id="mobilePopup" class="popup" v-if="$vuetify.breakpoint.xsOnly">
        <div
          class="popup-content"
          style="max-width: 100%; box-sizing: border-box; padding: 20px"
        >
          <span class="close-btn" style="cursor: pointer" @click="show = false"
            >&times;</span
          >
          <h2
            style="
              text-align: center;
              margin: 0;
              font-size: 27px;
              font-weight: 600;
              color: #ee1f51;
              font-family: 'Hepta Slab', serif;
            "
          >
            Make Donation
          </h2>
          <p
            style="
              text-align: center;
              font-size: 15px;
              margin: 0px;
              font-weight: 600;
            "
          >
            You are donating to:
          </p>

          <div
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              border: 0px solid #ccc;
              border-radius: 10px;
              width: 80%;
              max-width: 500px;
              margin: 0 auto;
              margin-bottom: 5px;
            "
          >
            <img
              v-if="
                selectedSearch.profile_pic !==
                'https://harlemwizardsinabox-prod-bucket.s3.us-east-1.amazonaws.com/media/Img/student.png'
              "
              :src="selectedSearch.profile_pic"
              alt="Profile Image"
              style="
                border-radius: 50%;
                width: 50px;
                height: 50px;
                margin-right: 10px;
                border: 1px solid #492f92;
              "
            />
            <v-avatar v-else color="red" size="40">
              <span class="white--text text-h6">{{
                getInitials(selectedSearch.name)
              }}</span>
            </v-avatar>
            <!-- Circle image -->
            <div
              style="display: flex; margin-left: 5px; flex-direction: column"
            >
              <v-list-item-title>
                <span class="searchList-title">{{ selectedSearch.name }}</span>

                <v-chip
                  small
                  class="mx-2 mt-n1"
                  :class="selectedSearch.user_type"
                  style="font-size: 13px"
                  v-if="selectedSearch.user_type !== 'student'"
                >
                  school
                </v-chip>
                <v-chip
                  small
                  class="mx-2 mt-n1"
                  :class="selectedSearch.user_type"
                  style="font-size: 13px"
                  v-else
                >
                  {{ selectedSearch.user_type }}
                </v-chip>
              </v-list-item-title>
              <v-list-item-subtitle
                class="searchList-secondryText"
                v-if="selectedSearch.user_type === 'student'"
                v-html="
                  `${selectedSearch.grade_teacher} (Grade ${selectedSearch.grade_title})`
                "
              ></v-list-item-subtitle>
              <v-list-item-subtitle
                class="searchList-secondryText"
                v-if="selectedSearch.user_type === 'student'"
                v-html="selectedSearch.school_name"
              ></v-list-item-subtitle>
              <v-list-item-subtitle
                class="searchList-secondryText"
                v-if="selectedSearch.user_type === 'teacher'"
                v-html="
                  `Grade ${selectedSearch.grade_title} (${selectedSearch.school_name})`
                "
              ></v-list-item-subtitle>
            </div>
          </div>

          <div
            style="
              display: flex;
              border: 1px solid #ccc;
              border-radius: 10px;
              padding: 8px;
              margin: 0 auto;
              margin-bottom: 30px;
              background-color: #f5f5f5cc;
              max-height: 56px;
              overflow-y: auto;
              width: 100%;
              box-sizing: border-box;
            "
         v-if="selectedSearch.user_type==='student'" >
            <p
              style="
                font-size: 10px;
                margin: 0;
                line-height: 1.5;
                white-space: normal;
                overflow-wrap: break-word;
              "
            >
              {{ plainSpecialMessage }}
            </p>
          </div>
          <div
            style="
              display: flex;
              border: 1px solid #ccc;
              border-radius: 10px;
              padding: 8px;
              margin: 0 auto;
              margin-bottom: 30px;
              background-color: #f5f5f5cc;
              max-height: 56px;
              overflow-y: auto;
              width: 100%;
              box-sizing: border-box;
            "
        v-else >
            <p
              style="
                font-size: 10px;
                margin: 0;
                line-height: 1.5;
                white-space: normal;
                overflow-wrap: break-word;
              "
            >
              {{ schoolplainSpecialMessage }}
            </p>
          </div>
          <h3
            style="
              font-family: 'Hepta Slab', serif;
              font-weight: bold !important;
              font-size: 18px;
              margin-top: -7%;
            "
          >
            Donor Information
          </h3>
          <v-form id="mobileForm" style="margin-top: -3%" ref="donationFormmob">
            <div class="form-row">
              <div class="form-group">
                <input
                  type="text"
                  id="first-name"
                  v-model="donorFirstName1"
                  name="first-name"
                  placeholder="First Name*"
                  required
                />
              </div>
              <div class="form-group">
                <input
                  type="text"
                  id="last-name"
                  v-model="donorLastName1"
                  name="last-name"
                  placeholder="Last Name*"
                  required
                />
              </div>
            </div>

            <!-- Row for Email and Phone -->
            <div class="form-row">
              <div class="form-group">
                <input
                  type="email"
                  id="email"
                  v-model="donorEmail1"
                  name="email"
                  placeholder="Email*"
                  required
                />
              </div>
              <div class="form-group">
                <input
                  type="tel"
                  id="phone"
                  v-model="donorPhone1"
                  name="phone"
                  placeholder="Phone*"
                  required
                  @keypress="isNumber($event)"
                />
              </div>
            </div>
            <p style="font-size: 12px">
              **Thank you for entering your information. This will allow us to
              remove you from recurring donation request.**
            </p>

            <!-- Donation Amount Field with Full Width inside the popup -->
            <div class="form-row" style="margin-bottom: 10px">
              <div
                class="form-group"
                style="width: 100%; box-sizing: border-box"
              >
                <label
                  for="donation-amount"
                  style="
                    font-size: 15px;
                    display: block;
                    margin-bottom: 5px;
                    margin-top: -8px;
                  "
                  >How much you want to donate?</label
                >
                <div style="position: relative">
                  <span
                    style="
                      position: absolute;
                      left: 10px;
                      top: 50%;
                      transform: translateY(-50%);
                      font-size: 15px;
                    "
                    >$
                  </span>
                  <input
                    type="number"
                    id="donation-amount"
                    name="donation-amount"
                    v-model="amount"
                    placeholder="Enter amount"
                    style="padding-left: 35px; width: calc(100% - 20px)"
                    required
                  />
                </div>
              </div>
            </div>

            <!-- Preset Donation Amount Buttons -->
            <div class="donation-buttons-row">
              <button type="button" class="donation-btn" @click="amount = 30">
                $30
              </button>
              <button type="button" class="donation-btn" @click="amount = 50">
                $50
              </button>
              <button type="button" class="donation-btn" @click="amount = 100">
                $100
              </button>
              <button type="button" class="donation-btn" @click="amount = 200">
                $200
              </button>
            </div>

            <!-- Checkbox for platform fee -->
            <div class="checkbox-container">
              <input
                type="checkbox"
                id="platform-fee-checkbox"
                v-model="checkbox"
                class="custom-checkbox"
              />
              <label for="platform-fee-checkbox" class="custom-label">
                <div class="custom-box"></div>
                I want to pay the platform fee too.
              </label>
            </div>

            <!-- Special Message Textarea -->
            <div class="form-row" style="margin-top: -10px">
              <div class="form-group" style="width: 100%">
                <label
                  for="special-message"
                  style="font-size: 15px; display: block; margin-bottom: 5px"
                  >Special Message</label
                >
                <v-textarea
                  v-model="donorSpecialMsg1"
                  outlined
                  rows="2"
                  hide-details
                  placeholder="Write special message"
                ></v-textarea>
              </div>
            </div>

            <!-- Other form elements here -->

            <!-- Dynamic Pay Button -->
            <button
              type="button"
              id="pay-button"
              style="
                margin-top: -15px;
                padding: 5px 20px;
                font-size: 15px;
                background-color: #ee1f51;
                color: white;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                display: block;
                width: auto;
                margin-left: auto;
                margin-right: auto;
                font-weight: 900;
              "
              :loading="payDonationBtnLoading1"
              :disabled="payDonationBtnDisabled"
              :dark="!payDonationBtnDisabled"
              right
              @click="payDonationHandlermob($event)"
            >
              Pay ${{ finalDonationAmount }}
            </button>
          </v-form>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import { CAMPAIGN_DETAIL, CAMPAIGN_ID, ID, SEARCH } from "@/constants/APIKeys";
import Axios from "@/api/BaseAxios";
import {
  API_PUBLIC_MASTER_SEARCH,
  API_PUBLIC_MAKE_DONATION,
  API_PUBLIC_MASTER_GATEWAY_PERCENTAGE,
  API_ADMIN_STUDENT_MANAGEMENT_GET,
} from "@/constants/APIUrls";
import RULES from "@/common/fieldRules";
import { STUDENT } from "@/constants/ModuleKeys";

export default {
  name: "MakeDonationModal",
  components: {},
  data() {
    // this.publishableKey = process.env.STRIPE_PUBLISHABLE_KEY;
    return {
      rules: RULES,
      //  pledge: false,
      stripeGatewayPercentage: 0,
      sessionId: "",
      publishableKey: "",
      stripeAccount: "",
      searchQuery: "",
      searchList: [],
      selectedSearch: {},
      searchLoading: false,
      donorFirstName: "",
      donorLastName: "",
      donorEmail: "",
      donorPhone: "",
      donorSpecialMsg: "",
      donorFirstName1: "",
      donorLastName1: "",
      donorEmail1: "",
      donorPhone1: "",
      donorSpecialMsg1: "",
      amount: 0,
      donationSelection: -1,
      payDonationBtnLoading: false,
      payDonationBtnLoading1: false,
      donationSuggestions: [30, 50, 100, 200],
      checkbox: true,
      specialMessage: "",
      showFullMessage: false,
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      showGetter: "publicDonationPage/getMakeDonationModalShow",
      campaign: "publicDonationPage/getCampaign",
      autoSelect: "publicDonationPage/getMakeDonationAutoSelect",
    }),
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        if (!value) this.clearModal();
        return this.toggleModal({ show: value });
      },
    },
    payDonationBtnDisabled() {
      let flag = true;
      if (!this.selectedSearch.name) flag = false;
      if (!this.amount) flag = false;
      return !flag;
    },
    finalDonationAmount() {
      if (this.checkbox) {
        let amt = 0;
        let a1 = parseFloat(this.amount) + 0.3;
        let fee = this.stripeGatewayPercentage / 100;
        let a2 = 1 - fee;
        amt = a1 / a2;
        amt = Math.floor(amt * 100) / 100 + 0.01;
        return amt.toFixed(2);
      } else {
        return parseFloat(this.amount).toFixed(2); // Return the original amount if the checkbox is unchecked
      }
    },

    getCampaignDetail() {
      return this.getCampaign;
    },
    studentDetail() {
      return this.getCampaign.student_detail;
    },
    studentID() {
      return this.$route.query[STUDENT];
    },
    plainSpecialMessage() {
      // Use a DOM parser to strip out any HTML tags from specialMessage
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = this.specialMessage;
      return tempDiv.textContent || tempDiv.innerText || "";
    },
    getCampaignDetailmessage() {
      return this.campaign.campaign_detail;
    },
    getDetailMessage() {
      return this.getCampaignDetailmessage.special_message;
    },
     schoolplainSpecialMessage() {
      // Use a DOM parser to strip out any HTML tags from specialMessage
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = this.getDetailMessage;
      return tempDiv.textContent || tempDiv.innerText || "";
    },
  },
  watch: {
    //Watcher for detecting change in SEARCH
    searchQuery: function () {
      this.searchLoading = true;
      this.debounceGetCampaignData();
    },
    show(item) {
      if (item) {
        this.selectedSearch = this.autoSelect ? this.autoSelect : {};
        console.log("this.selectedSearch", this.selectedSearch);
      }
    },
    // checkbox() {
    //   if (this.checkbox) {
    //     let amt = 0;
    //     let a1 = this.amount + 0.4;
    //     let a2 = 1 - this.stripeGatewayPercentage / 100;
    //     amt = a1 / a2;
    //     console.log(amt)
    //   }
    // },
  },
  methods: {
    ...mapActions({
      toggleModal: "publicDonationPage/toggleMakeDonationModal",
      showToast: "snackBar/showToast",
    }),
    openModal() {
      this.getGatewayPercentage();
    },
    clearModal() {
      this.searchQuery = "";
      this.searchList = [];
      this.selectedSearch = {};
      this.searchLoading = false;
      this.donorFirstName = "";
      this.donorLastName = "";
      this.donorEmail = "";
      this.donorSpecialMsg = "";
      this.amount = 0;
      this.donationSelection = -1;
      this.checkbox = true;
      this.payDonationBtnLoading = false;
      this.sessionId = "";
      this.publishableKey = "";
      this.stripeAccount = "";
    },
    toggleMessage() {
      this.showFullMessage = !this.showFullMessage;
    },
    payDonationHandler() {
      if (this.$refs.donationForm.validate()) {
        this.payDonationBtnLoading = true;
        let formData = {};
        formData[
          "donor_name"
        ] = `${this.donorFirstName.trim()} ${this.donorLastName.trim()}`;
        formData["donor_email"] = this.donorEmail;
        formData["phone"] = this.donorPhone;
        formData["amount"] = this.amount;
        formData["donor_special_message"] = this.donorSpecialMsg;
        formData["campaign"] = this.campaign[CAMPAIGN_DETAIL][ID];
        formData["payment_mode"] = "Online";
        formData["donation_for"] = this.selectedSearch.user_type;
        formData[this.selectedSearch.user_type] = this.selectedSearch.id;
        formData["user_paid_processing_fees"] = this.checkbox;
        //  formData["pledge"] = this.pledge;
        if (this.campaign.type) {
          let type_id = this.campaign.type.type + "_id";
          formData["cp_name"] = this.campaign.type.type;
          formData["cp_value"] = this.campaign.type[type_id];
        }
        const successHandler = (res) => {
          console.log("Search List: ", res.data);
          // You will be redirected to Stripe's secure checkout page
          this.publishableKey = res.data.stripe_publish_key;
          this.stripeAccount = res.data.account_id;
          this.sessionId = res.data.transcation_id;
          console.log(
            "publishableKey:",
            this.publishableKey,
            "stripeAccount: ",
            this.stripeAccount,
            "this.sessionId: ",
            this.sessionId
          );
          this.setupStrip();
        };
        const failureHandler = (res) => {
          console.log("Failure: ", res);
          this.clearModal();
          this.payDonationBtnLoading = false;
          this.toggleModal({ show: false });
        };
        Axios.request_POST(
          API_PUBLIC_MAKE_DONATION,
          formData,
          {},
          successHandler,
          failureHandler,
          false,
          false
        );
      }
    },
    payDonationHandlermob(event) {
      event.preventDefault(); // Prevent the form from refreshing

      if (this.$refs.donationFormmob.validate()) {
        this.payDonationBtnLoading1 = true;
        let formData = {};
        formData[
          "donor_name"
        ] = `${this.donorFirstName1.trim()} ${this.donorLastName1.trim()}`;
        formData["donor_email"] = this.donorEmail1;
        formData["phone"] = this.donorPhone1;
        formData["amount"] = this.amount;
        formData["donor_special_message"] = this.donorSpecialMsg1;
        formData["campaign"] = this.campaign[CAMPAIGN_DETAIL][ID];
        formData["payment_mode"] = "Online";
        formData["donation_for"] = this.selectedSearch.user_type;
        formData[this.selectedSearch.user_type] = this.selectedSearch.id;
        formData["user_paid_processing_fees"] = this.checkbox;

        if (this.campaign.type) {
          let type_id = this.campaign.type.type + "_id";
          formData["cp_name"] = this.campaign.type.type;
          formData["cp_value"] = this.campaign.type[type_id];
        }

        const successHandler = (res) => {
          console.log("Search List: ", res.data);
          // You will be redirected to Stripe's secure checkout page
          this.publishableKey = res.data.stripe_publish_key;
          this.stripeAccount = res.data.account_id;
          this.sessionId = res.data.transcation_id;
          console.log(
            "publishableKey:",
            this.publishableKey,
            "stripeAccount: ",
            this.stripeAccount,
            "this.sessionId: ",
            this.sessionId
          );
          this.setupStrip();
        };

        const failureHandler = (res) => {
          console.log("Failure: ", res);
          this.clearModal();
          this.payDonationBtnLoading1 = false;
          this.toggleModal({ show: false });
        };

        Axios.request_POST(
          API_PUBLIC_MAKE_DONATION,
          formData,
          {},
          successHandler,
          failureHandler,
          false,
          false
        );
      }
    },

    getCampaignSearchList() {
      const _this = this;
      const successHandler = (res) => {
        _this.searchList = res.data.search_list;
        _this.searchLoading = false;
      };
      const failureHandler = (res) => {
        console.log("Failure: ", res);
        _this.searchLoading = false;
      };
      let formData = {};
      formData[SEARCH] = this.searchQuery;
      formData[CAMPAIGN_ID] = this.campaign[CAMPAIGN_DETAIL][ID];
      Axios.request_GET(
        API_PUBLIC_MASTER_SEARCH,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        false
      );
    },
    searchItemClickHandler(item) {
      this.selectedSearch = item;
      this.searchQuery = "";
      this.searchList = [];
    },
    debounceGetCampaignData: _.debounce(function () {
      this.getCampaignSearchList();
    }, 500),
    includeStripe(URL, callback) {
      let documentTag = document,
        tag = "script",
        object = documentTag.createElement(tag),
        scriptTag = documentTag.getElementsByTagName(tag)[0];
      object.src = "//" + URL;
      if (callback) {
        object.addEventListener(
          "load",
          function (e) {
            callback(null, e);
          },
          false
        );
      }
      scriptTag.parentNode.insertBefore(object, scriptTag);
    },
    async configureStripe() {
      if (this.stripeAccount) {
        this.stripe = await window.Stripe(this.publishableKey, {
          stripeAccount: this.stripeAccount,
        });
      } else {
        this.stripe = await window.Stripe(this.publishableKey);
      }

      await this.stripe.redirectToCheckout({ sessionId: this.sessionId });
    },
    /**
     * function returns user's initials
     * param {string}  user's full name
     **/
    getInitials(name) {
      let initials = name.split(" ");
      if (initials.length > 1) {
        initials = initials.shift().charAt(0) + initials.pop().charAt(0);
      } else {
        initials = name.substring(0, 2);
      }
      return initials.toUpperCase();
    },
    async setupStrip() {
      await this.includeStripe(
        "js.stripe.com/v3/",
        function () {
          this.configureStripe();
        }.bind(this)
      );
    },
    validate_amount(amount) {
      console.log(amount);
      if (this[amount] - Math.floor(this[amount]) !== 0) {
        console.log(this[amount], typeof this[amount], "amount");
        this[amount] = this[amount].split(".")[0];
      }
    },
    getGatewayPercentage() {
      const successHandler = (res) => {
        console.log(
          "stripe_gateway_percentage: ",
          res.data.stripe_gateway_percentage
        );
        this.stripeGatewayPercentage = res.data.stripe_gateway_percentage;
      };
      const failureHandler = (res) => {
        console.log("Failure: ", res);
      };
      let formData = {};
      Axios.request_GET(
        API_PUBLIC_MASTER_GATEWAY_PERCENTAGE,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        false
      );
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    getStudentDetail() {
      const self = this;
      const successHandler = (res) => {
        const data = res.data;
        self.specialMessage = data.student_detail.student_special_message;
      };
      const failureHandler = (res) => {
        const data = res.data;
        console.log(data);
      };

      let formData = {};
      formData["student_id"] = this.studentID;
      console.log(formData);

      Axios.request_GET(
        API_ADMIN_STUDENT_MANAGEMENT_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true
      );
    },
  },
  mounted() {
    this.getGatewayPercentage();
    if (this.studentID) {
      this.getStudentDetail();
    }
  },
};
</script>

<style scoped>
.modalTitle {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 315%;
  color: #ffffff;
}
.sectionTitle span {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 32px;
  color: #2c1963;
}
.sectionTitle > .sectionTitleUnderline {
  background: #2c1963;
  border-radius: 45px;
  width: 80%;
  border-width: 1.5px;
}
.textField-label {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 25px;
  color: #2d2d2d;
}
.payDonation-btn span {
  letter-spacing: 0px;
  text-transform: none;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 161.5%;
  color: #ffffff;
}
.donationSuggestion-item {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #5c5c5c;
}
.searchList-title {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 161.5%;
  color: #1d1d1d;
}
.searchList-secondryText {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 161.5%;
  color: #757575;
}
.student {
  background: rgba(0, 133, 255, 0.12) !important;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 161.5%;
  color: #0085ff !important;
  text-transform: capitalize;
}
.teacher {
  background: rgba(56, 167, 55, 0.12) !important;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 161.5%;
  color: #38a737 !important;
  text-transform: capitalize;
}
.school {
  background: rgba(45, 45, 45, 0.12) !important;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 161.5%;
  color: #2d2d2d !important;
  text-transform: capitalize;
}
.thankyoumsg {
  font-family: auto;
  font-size: 18px;
  margin-bottom: 25px;
  margin-left: 15px;
  margin-top: -21px;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .modalTitle {
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 219%;
    color: #ffffff;
  }
  .sectionTitle span {
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    color: #2c1963;
  }
  .textField-label {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 25px;
    color: #2d2d2d;
  }
  .payDonation-btn span {
    letter-spacing: 0px;
    text-transform: none;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 161.5%;
    color: #ffffff;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .modalTitle {
    font-size: 15px; /* Reduce title font size */
  }
  .sectionTitle span {
    font-size: 13px; /* Smaller section titles */
  }
  .textField-label {
    font-size: 13px; /* Reduce text field labels */
  }
  .payDonation-btn span {
    font-size: 13px; /* Adjust button text size */
  }
  .v-card-text {
    height: auto; /* Let the card adjust its height */
  }
  v-card {
    height: 90vh; /* Limit the dialog height */
  }
  .searchList-title {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 161.5%;
    color: #1d1d1d;
  }
  .searchList-secondryText {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 161.5%;
    color: #757575;
  }
  .clamp {
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Show only 3 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .thankyoumsg {
    font-family: auto;
    font-size: 14px;
    margin-bottom: 25px;
    margin-left: 15px;
    margin-top: -21px;
  }
}
/* Popup container - hidden by default */
/* Popup container - hidden by default */
.popup {
  display: block;
  position: fixed;
  z-index: 100;
  left: -5;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Background overlay */
  overflow: auto; /* Allow scrolling if content overflows */
}

/* Popup content */
.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  margin: 5vh auto; /* Adjusted margin for better centering */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  max-width: 600px; /* Max width */
  height: 75vh; /* Occupy 80% of the viewport height */
  max-height: 75vh; /* Prevent exceeding the viewport height */
  overflow-y: auto; /* Allow scrolling for large content */
}

/* Adjust content size based on viewport height */
.popup-content * {
  /* font-size: calc(1rem + 1vh); Dynamic font size based on viewport height */
  /*line-height: 1.7;  Improve readability */
}

/* Close button */
.close-btn {
  color: #ee1f51;
  float: right;
  font-size: 35px;
  font-weight: bold;
}

.close-btn:hover,
.close-btn:focus {
  color: #ee1f51;
  text-decoration: none;
  cursor: pointer;
}

/* Form inside popup */
form {
  display: flex;
  flex-direction: column;
}

/* Responsive form rows */

/* Form field styling */
form label {
  margin-top: 10px;
  font-weight: bold;
}

form input,
form textarea {
  padding: 10px 0px;
  margin-top: 5px;
  font-size: 11px; /* Dynamic font size based on viewport height */
  border: none;
  border-bottom: 2px solid #ccc; /* Add bottom border only */
  width: 100%;
  outline: none;
  transition: border-color 0.3s;
}

form input:focus,
form textarea:focus {
  border-bottom-color: #007bff;
}

/* Donation buttons row */
.donation-buttons-row {
  display: flex;
  justify-content: center;
  gap: 5px; /* Space between buttons */
  flex-wrap: nowrap;
}

.donation-btn {
  flex: 1;
  min-width: 70px;
  padding: 10px 0;
  background-color: #f5f5f5cc;
  color: black;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 900;
}

/* Stack fields on small screens */
@media (max-width: 768px) {
  .popup-content {
    width: 95%;
    height: 90vh; /* Occupy more height on small screens */
    max-height: 90vh;
    margin-top: 4%;
    margin-left: 1%;
  }
}

/* Adjustments for extra small screens */
@media (max-width: 400px) {
  .form-row {
    gap: 15px;
  }

  .donation-btn {
    min-width: 50px; /* Smaller button size for small screens */
  }
}

/* Checkbox container styling */
.checkbox-container {
  display: flex;
  align-items: left;
  justify-content: left;
  margin-top: 10px;
}

.custom-checkbox {
  display: none;
}

.custom-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
}

.custom-box {
  width: 20px;
  height: 20px;
  border: 1px solid #ee1f51;
  border-radius: 4px;
  position: relative;
  margin-right: 5px;
}

.custom-checkbox:checked + .custom-label .custom-box::after {
  content: "";
  position: absolute;
  left: 5px;
  top: 0px;
  width: 5px;
  height: 12px;
  border: solid #ffffff;
  border-width: 0 4px 4px 0;
  transform: rotate(45deg);
}

.custom-checkbox:checked + .custom-label .custom-box {
  background-color: #ee1f51;
  border-color: #ee1f51;
}
.form-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 5px; /* Reduce space between rows */
}

/* Form group styles */
.form-group {
  flex: 1;
}

/* Input field styles */
input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}
.v-list-item__avatar {
  align-self: center;
  justify-content: flex-start;
  margin-bottom: 44px;
}
.custom-avatar {
  width: 100px !important; /* Set desired size */
  height: 100px !important; /* Match width */
  border-radius: 50% !important; /* Ensure circular shape */
}
.custom-avatar v-img {
  width: 100%; /* Make the image fill the container */
  height: 100%;
}
</style>
